import { useState, useEffect, memo, useContext } from "react";
import { Form } from "react-bootstrap";
import { HiSearch } from "react-icons/hi";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { getRefundOrders } from "../../api/refund";
import { LanguageContext } from "../../contexts/LanguageContext";

const filterOptions = [
  { id: "refundOrderID", title: "RMA ID" },
  { id: "orderID", title: "Order ID" },
  { id: "refundOrderCreatedAt", title: "Request Date" },
  { id: "customerName", title: "Customer Name" },
  { id: "rmaManager", title: "RMA Manager" },
  { id: "refundOrderStatusName", title: "Status" },
  { id: "refundOrderCustomerEmail", title: "Email Address" },
  { id: "refundOrderCustomerPhone", title: "Phone Number" },
];

/**
 * Tab child component in /account
 * @returns {JSX.Element}
 */
const RefundHistoryTab = () => {
  const [filteredRefunds, setFilteredRefunds] = useState([]);
  const [refundOrders, setRefundOrders] = useState([]);
  const [filter, setFilter] = useState(filterOptions[0].id);
  const [filterText, setFilterText] = useState("");
  const { language } = useContext(LanguageContext);

  const applyFilter = () => {
    if (!filterText) {
      setFilteredRefunds([...refundOrders])
      return;
    }
    if (["refundOrderID", "orderID", "rmaManager"].includes(filter)) {
      // Filter by number
      setFilteredRefunds(refundOrders.filter((refund) =>
        refund[filter] === Number(filterText)
      ));
    } else if (["refundOrderStatusName", "refundOrderCustomerEmail", "refundOrderCustomerPhone"].includes(filter)) {
      // Filter by string
      setFilteredRefunds(refundOrders.filter((refund) =>
        refund[filter].toLowerCase().includes(filterText.toLowerCase())
      ));
    } else if (["refundOrderCreatedAt"].includes(filter)) {
      // Filter by date
      setFilteredRefunds(refundOrders.filter((refund) =>
        new Date(refund.refundOrderCreatedAt).getTime() === new Date(filterText).getTime()
      ));
    }
  };

  //get refund orders
  useEffect(() => {
    const fetchRefundOrders = async () => {
      try {
        const { result } = await getRefundOrders();
        setRefundOrders(result?.data);
        setFilteredRefunds(result?.data);
      } catch (err) {
        console.error(err);
      }
    };
    fetchRefundOrders();
  }, []);

  return (
    <div className="purchaseHistorySec">
      {/* Refund Filter */}
      <div className="refund-filter-container">
        <span>Filter</span>
        <Form.Select
          id="refund-filter-option"
          name="refund-filter-option"
          className="block form-control input-relative"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        >
          {filterOptions.map(({ id, title }) => (
            <option key={id} value={id}>
              {title}
            </option>
          ))}
        </Form.Select>
        <input
          type="text"
          name="refund-filter-value"
          id="refund-filter-value"
          className="block form-control"
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
        />
        <button onClick={applyFilter} className="cta">
          <HiSearch className="search-icon" />
        </button>
      </div>

      {filteredRefunds && filteredRefunds.length > 0 ? (
        <div className="refund__listing">
          {filteredRefunds.map(
            ({
              refundOrderID,
              refundOrderStatusName,
              refundItems,
              orderID,
              refundOrderCustomerEmail,
              refundOrderCustomerPhone,
              refundOrderCreatedAt,
            }) => (
              <div key={refundOrderID} className="card">
                {/* top */}
                <div className="top">
                  <div className="metadata">
                    <p className="credit-note">
                      <span>Credit Note:</span> Sent on{" "}
                      {refundOrderCreatedAt || "-"}
                    </p>
                    <p className="rma-id">RMA ID: {refundOrderID}</p>
                  </div>
                  <div className="metadata">
                    <p
                      className={clsx(
                        "status",
                        refundOrderStatusName.toLowerCase() ===
                          "completed"
                          ? "completed"
                          : refundOrderStatusName.toLowerCase() ===
                            "rejected"
                          ? "rejected"
                          : "pending"
                      )}
                    >
                      {refundOrderStatusName}
                    </p>
                    <Link className="link" to={`/${language ? "en" : "th"}/orders/${btoa(orderID)}`}>
                      <p className="order-id">Order ID: {orderID}</p>
                    </Link>
                  </div>
                </div>

                {/* bottom */}
                <div className="bottom">
                  <div className="container">
                    <div className="refund-items">
                      {refundItems.map(
                        ({ itemImage, refundItemSKUName }, index) => (
                          <div key={index} className="refund-item">
                            <img className="refund-image" src={itemImage} />
                            <p className="item-name">
                              {refundItemSKUName}
                            </p>
                          </div>
                        )
                      )}
                    </div>

                    <div className="cta">
                      <Link to={`/${language ? "en" : "th"}/refunds/${btoa(refundOrderID)}`} className="view-details">
                        View Details
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      ) : (
        <div className="d-flex data_wrap row">
          <span className="errormsg text-center txt-30">
            No Result Found
          </span>
        </div>
      )}
    </div>
  )
}

export default memo(RefundHistoryTab);