import { memo } from "react";
import { useContext } from "react";
import { LanguageContext } from "../../contexts/LanguageContext";

function WishlistItem({ product, handleClick, addToCart, error }) {
  //context
  const { language } = useContext(LanguageContext);

  return (
    <div
      id={"cart_" + product?.itemId}
      className=" py-2  cart_Row d-flex justify-content-between border-cart-divide"
    >
      <div className="cart_Row">
        <div className="d-flex align-items-center">
          <input
            type="checkbox"
            className="cus_checkbox px-2 form-check-input"
            onChange={() => handleClick(product?.itemId)}
            checked={product.checked}
          />
        </div>
      </div>
      <div className="cart_Row me-3">
        <img src={product?.productImage} alt="" className="img-thumbnail" />
      </div>

      <div className="cart_Row  w-75">
        <div className="d-md-flex pt-2 w-100 align-items-center justify-content-between mb-3 ">
          <h5 className="wid-50 mb-3">
            {language ? (
              <>{product?.productName}</>
            ) : (
              <>{product?.productName}</>
            )}
          </h5>
          <div className="wid-50 d-md-flex align-items-center gap-2">
            <div className="wid-50 mb-3">
              <h3 className="text-price text-red-1 txt-30 mb-2 text-end">
                <sub>THB</sub>
                {Math.ceil(product?.productPrice).toFixed(2)}
              </h3>
            </div>
            {Number(product?.productStocks) > 0 && (
              <div className="wid-50">
                <button
                  className="btn-card-sec d-table ms-auto mb-4"
                  onClick={() => addToCart(product?.itemId, product?.productId)}
                >
                  Add to Cart
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(WishlistItem);
