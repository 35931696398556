import axios from "axios";

const productClient = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_ENDPOINT}/products/homepage`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export const getProductsHome = async ({
  category,
  tag,
  sortKey,
  sortDirection,
  nameFilter,
  page,
  pageSize,
}) => {
  try {
    const { data } = await productClient.post("", {
      filter: {
        page,
        pageSize,
        category,
        tag,
        name: nameFilter,
      },
      sort: {
        key: sortKey,
        direction: sortDirection,
      },
    });
    const { result, status } = data;

    return {
      data: result?.data,
      status: status,
      length: result.totalPages,
    };
  } catch (err) {
    console.error(err);
  }
};
