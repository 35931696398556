import axios from "axios";

const categoryClient = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_ENDPOINT}/categories`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export const getCategoryByID = async (categoryID) => {
  const { data } = await categoryClient.get(`/${categoryID}`);

  return data;
};
