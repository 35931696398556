import { useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { LanguageContext } from "../../contexts/LanguageContext";
import "../../components/login/LoginForm.css";

const OrderCancelled = () => {
  const { language } = useContext(LanguageContext);

  return (
    <div className="bg-grey-6 py-5">
      <Container fluid className="my-3 my-md-4">
        <Row>
          <Col>
            <div className="form_login bg-white p-5">
              <form className="container-lg p-0 p-md-5">
                <Row className="mb-3">
                  <Col>
                    <div className="d-md-flex">
                      <h2 className="mb-4 text-red-1">
                        {language
                          ? "Your Order Payment Cancelled"
                          : "การชำระเงินคำสั่งซื้อของคุณถูกยกเลิก"}
                      </h2>
                    </div>

                    <p className="mb-4 text-grey-1">
                      {language
                        ? "The Payment Process has been cancelled, please try again later."
                        : "กระบวนการชำระเงินถูกยกเลิก โปรดลองอีกครั้งในภายหลัง"}
                    </p>
                  </Col>
                </Row>

                <hr />
              </form>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default OrderCancelled;
