import axios from "axios";
import { getAccessToken } from "../utils/jwt";
import { checkAccessToken } from "./interceptor";

export const accountClient = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_ENDPOINT}/account`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${getAccessToken()}`,
  },
});

accountClient.interceptors.request.use(checkAccessToken);

export const getPersonalInfo = async ({ userID }) => {
  const { data } = await accountClient.get(`/${userID}`);
  const { Status, Users } = data;

  return {
    status: Status,
    users: Users,
  };
};

export const updatePersonalInfo = async ({ userID, userDetails }) => {
  const { data } = await accountClient.patch(`/${userID}`, userDetails);

  return {
    status: data.Status,
  };
};

export const createAccount = async (payload) => {
  const { data } = await accountClient.put("/", payload);
  const { Status, UserID } = data;

  return {
    status: Status,
    userID: UserID,
  };
};
