import { useEffect, useState, useRef, memo, useContext } from "react";
import LazyLoad from "react-lazy-load";
import { Container, Row, Col } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getFooterBanners } from "../../api/banners";
import { LanguageContext } from "../../contexts/LanguageContext";
import "./Info.css";

function InfoSec() {
  const [show, setShow] = useState(false);
  const [youtubeURL, setYoutubeURL] = useState("");
  const [bannerItems, setBannerItems] = useState([]);
  const modalRef = useRef(null);
  const { language } = useContext(LanguageContext);

  useEffect(() => {
    const getBannersItems = async () => {
      try {
        const { banners } = await getFooterBanners(language);
        setBannerItems(banners);
      } catch (err) {
        console.error(err);
      }
    };
    getBannersItems();
  }, [language]);

  const handleShow = (youtubeURL) => {
    setYoutubeURL(youtubeURL);
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setYoutubeURL("");
  };

  const handleModalClick = (e) => {
    if (e.target === modalRef.current) {
      handleClose();
    }
  };

  return (
    <>
      <Container fluid>
        <Modal
          size="lg"
          centered
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <div className="modal-container" ref={modalRef}>
            <Modal.Body className="modal-body p-0">
              <div className="close-button" onClick={handleClose}>
                X
              </div>
              <div className="modal-video-container" onClick={handleModalClick}>
                <iframe
                  src={youtubeURL}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
              </div>
            </Modal.Body>
          </div>
        </Modal>

        <Row>
          {bannerItems.map((item, index) => (
            <Col key={index} xs={12} md={6} className="p-0">
              {!!item.Left.youtubeURL ? (
                <Link to="/">
                  <LazyLoad threshold={0}>
                    <img
                      className="d-block w-100"
                      src={item.Left.imageBinary}
                      alt="info"
                    />
                  </LazyLoad>
                </Link>
              ) : !!item.Left.imageBinary ? (
                <Link to={item.Left.bannerURL}>
                  <LazyLoad threshold={0}>
                    <img
                      className="d-block w-100"
                      src={item.Left.imageBinary}
                      alt="info"
                    />
                  </LazyLoad>
                </Link>
              ) : (
                <></>
              )}
            </Col>
          ))}

          {bannerItems.map((item, index) => (
            <Col key={index} xs={12} md={6} className="p-0">
              {!!item.Right.youtubeURL ? (
                <LazyLoad threshold={0}>
                  <div onClick={() => handleShow(item.Right.youtubeURL)}>
                    <img
                      className="d-block w-100"
                      src="/youtubethumbnail.png" //this image is hardcoded so make sure to change it for cms integration
                      alt="info"
                    />
                  </div>
                </LazyLoad>
              ) : !!item.Right.imageBinary ? (
                <Link to={item.Right.bannerURL}>
                  <LazyLoad threshold={0}>
                    <img
                      className="d-block w-100"
                      src={item.Right.imageBinary}
                      alt="info"
                    />
                  </LazyLoad>
                </Link>
              ) : (
                null
              )}
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
}

export default memo(InfoSec);
