import { useEffect, useContext, useState, memo } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { LanguageContext } from "../../contexts/LanguageContext";
import { getPromotions } from "../../api/promotion";
import { Loader } from "../";
import "./Promotions.css";
import { PromotionCard } from "../";

const Promotions = () => {
  const [activePromotions, setActivePromotions] = useState([]);
  const [loading, setLoading] = useState(false);
  const { language } = useContext(LanguageContext);

  useEffect(() => {
    const fetchActivePromotions = async () => {
      try {
        setLoading(true);
        const { data } = await getPromotions({
          showedStatus: "active",
          itemsPerPage: 4,
          language: language ? "en-US" : "th-TH",
        });
        if (!data || data.length <= 0) return;
        setActivePromotions(data);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };
    fetchActivePromotions();
  }, [language]);

  return (
    <>
      {loading && <Loader status={loading} />}
      <Container fluid className="py-5 bg-yellow-1">
        <Row>
          <Col>
            <Container fluid="xxl" className="mb-0">
              <Row className="align-items-md-center">
                <Col xs={12} lg={3}>
                  <h2 className="title-left">
                    {language ? "Exclusive Promotions" : "โปรโมชั่นพิเศษ"}
                  </h2>
                  <h5 className="text-normal mb-4">
                    {language
                      ? "Check out our latest promotions this month"
                      : "ตรวจสอบโปรโมชั่นล่าสุดของเราในเดือนนี้"}
                  </h5>
                </Col>

                <Col xs={12} xl={9} className="d-block">
                  <Row className="justify-content-end">
                    {activePromotions?.slice(0, 4).map((item, idx) => (
                      <PromotionCard key={idx} item={item} />
                    ))}
                  </Row>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default memo(Promotions);
