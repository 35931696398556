import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { getCategoryByID } from "../../api/subcategory.js";
import { LanguageContext } from "../../contexts/LanguageContext";
import { AppContext } from "../../contexts/AppContext.js";
import { DataSec, ImageSkeleton } from "../../components";
import "./Category.css";

/**
 * Renders the category page by the route /category/:id
 * @returns {JSX.Element} - The Category page
 */
const Category = () => {
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [category, setCategory] = useState({});
  const { language } = useContext(LanguageContext);
  const { setTitle } = useContext(AppContext);

  useEffect(() => {
    const fetchCategoriesBanner = async () => {
      setCategory({});
      setLoading(true);
      try {
        const { category } = await getCategoryByID(atob(id));
        setCategory(category);
        setTitle(category.categoryName[language ? "EN" : "TH"]);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    }
    fetchCategoriesBanner();
  }, [id]);

  return (
    <>
      <Container fluid className="">
        <Row className="mb-2 mb-md-4">
          <Col style={{ padding: 0 }}>
            {loading ? (
              <ImageSkeleton />
            ) : (
              <img
                className="d-block banner__image"
                src={category?.bannerURL?.[language ? "EN" : "TH"]}
              />
            )}
          </Col>
        </Row>
      </Container>
      {!!category ? (
        <DataSec category={category} />
      ) : (
        null
      )}
    </>
  );
}

export default Category;
