import { useContext, useState } from "react";
import LazyLoad from "react-lazy-load";
import Container from "react-bootstrap/Container"; //react-bootstrap
import Row from "react-bootstrap/Row"; //react-bootstrap
import Col from "react-bootstrap/Col"; //react-bootstrap
import Swal from "sweetalert2";
import { subscribeEmail } from "../../api/enginemailer";
import { LanguageContext } from "../../contexts/LanguageContext";
import "./Newsletter.css";

function Subscribe() {
  const { language } = useContext(LanguageContext);
  const [email, setEmail] = useState("");
  const [isValid, setIsValid] = useState(true);

  const handleClickSubscribe = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email.match(emailRegex)) {
      // Invalid email
      Swal.fire({
        title: "Invalid Email Format",
        icon: "error",
        confirmButtonText: "Close",
        confirmButtonColor: "#cc3500",
      });
      return;
    }
    const result = await subscribeEmail({email});
    switch (result) {
      case "200":
        Swal.fire({
          title: "Thank You For Subscribing!",
          icon: "success",
          confirmButtonText: "Close",
          confirmButtonColor: "#cc3500",
        });
        break;
      case "500":
        Swal.fire({
          title: "You have subscribed.",
          icon: "error",
          confirmButtonText: "Close",
          confirmButtonColor: "#cc3500",
        });
        break;
      }
  };

  return (
    <>
      <Container fluid className="bg-yellow-1">
        <Row className="align-items-center p-4">
          <Col xs={12} md={6} lg={7} className="p-3 p-md-4">
            <div className="d-flex align-items-center">
              <LazyLoad threshold={0}>
                <img
                  className="d-block img-fluid px-2"
                  src={process.env.PUBLIC_URL + "/assets/ico_subscribe.png"}
                  alt="info"
                />
              </LazyLoad>
              <h3 className="px-3 px-lg-5 mb-0">
                {language
                  ? "Subscribe to our newsletter for the best deals in your inbox."
                  : "สมัครรับข่าวสารกับเราเพื่อรับสิทธิพิเศษทางอีเมล"}
              </h3>
            </div>
          </Col>
          <Col xs={12} md={6} lg={5} className="p-3 p-lg-5">
            <div className="input_search">
              <input
                className={`form-control ${isValid ? "" : "is-invalid"}`}
                placeholder={language ? "Your email address" : "อีเมลของคุณ"}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <div
                className="box_search_subscribe bg-red-1 link_ text-center"
                onClick={handleClickSubscribe}
              >
                {language ? "Subscribe" : "สมัครรับข่าวสาร"}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Subscribe;
