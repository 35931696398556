import axios from "axios";

export const engineMailerClient = axios.create({
  baseURL: `${process.env.REACT_APP_ENGINEMAILER_BASE_URL}`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    apiKey: `${process.env.REACT_APP_ENGINEMAILER_API_KEY}`,
  },
});

export const subscribeEmail = async ({ email }) => {
  try {
    const response = await engineMailerClient.post(
      "/subscriber/emsubscriber/insertSubscriber",
      {
        email: email,
        subcategories: [2],
      }
    );
    if (!!response && !!response.data) {
      switch (response.data.Result.StatusCode) {
        case "200":
          const data = {
            UserKey: `${process.env.REACT_APP_ENGINEMAILER_USER_KEY}`,
            APIKey: `${process.env.REACT_APP_ENGINEMAILER_API_KEY}`,
            ToEmail: email,
            SenderEmail: "support@mrdiy.com",
            SenderName: "MrDIY Support",
            TemplateId: 2,
          };
          sendEmail(data);
          break;
      }
    }
    return response.data.Result.StatusCode;
  } catch (err) {
    return "500";
  }
};

export const sendFullTaxInvoiceEmail = async (payload) => {
  try {
    const submittedContent = `<html>
        <head>
        <style>
        table {
          font-family: arial, sans-serif;
          border-collapse: collapse;
          width: 100%;
        }
        
        td, th {
          border: 1px solid #dddddd;
          text-align: left;
          padding: 8px;
        }
        
        tr:nth-child(even) {
          background-color: #dddddd;
        }
        </style>
        </head>
        <body>
            <br>
            <div>
                <table>
                <tbody>
                    <tr>
                    <th>Order ID</th>
                    <td>${payload.orderId}</td>
                    </tr>
                    <tr>
                    <th>Customer Type</th>
                    <td>${payload.CustomerType}</td>
                    </tr>
                    <tr>
                    <th>Customer Full Name</th>
                    <td>${payload.Fullname}</td>
                    </tr>
                    <tr>
                    <th>Customer Tax ID</th>
                    <td>${payload.TaxIdField}</td>
                    </tr>
                    <tr>
                    <th>Customer Phone Number</th>
                    <td>${payload.CustomerPhone}</td>
                    </tr>
                    <tr>
                    <th>Customer Email</th>
                    <td>${
                      payload.CustomerEmail ? payload.CustomerEmail : ""
                    }</td>
                    </tr>
                    <tr>
                    <th>Customer Address</th>
                    <td>${payload.AddressLine_1}</td>
                    </tr>
                    <tr>
                    <th>Customer Address Postcode</th>
                    <td>${payload.Postcode}</td>
                    </tr>
                    <tr>
                    <th>Customer Address City</th>
                    <td>${payload.AddressCity}</td>
                    </tr>
                    <tr>
                    <th>Customer Address Suburbs</th>
                    <td>${payload.AddressSuburbs}</td>
                    </tr>
                    <tr>
                    <th>Customer Address Country</th>
                    <td>${payload.Country}</td>
                    </tr>
                </tbody>
                </table>
            </div>
            </body>
        </html>`;
    const data = {
      UserKey: `${process.env.REACT_APP_ENGINEMAILER_USER_KEY}`,
      APIKey: `${process.env.REACT_APP_ENGINEMAILER_API_KEY}`,
      ToEmail: "panicha.p@mrdiy.com",
      CCEmails: ["th-cs.general@mrdiy.com", "th.ecommerce.mp@mrdiy.com"],
      SenderEmail: "support@mrdiy.com",
      SenderName: "MrDIY Support",
      Subject: "Full Tax Invoice Request",
      SubmittedContent: submittedContent,
    };
    sendEmail(data);
  } catch (err) {
    return err;
  }
};

export const sendEmail = async (payload) => {
  try {
    const data = await engineMailerClient.post(
      "/Submission/SendEmail",
      payload
    );
    return data;
  } catch (err) {
    return err;
  }
};
