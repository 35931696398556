import { createContext, useState, useEffect } from "react";
import { getShoppingCartByID } from "../api/shoppingCart";
import { getAccessToken } from "../utils/jwt";

export const CartContext = createContext(""); //context loginform

export const CartProvider = ({ children }) => {
  const customerDetails = getAccessToken({ parse: true });
  const [cartID, setCartID] = useState(0);
  const [cartItems, setCartItems] = useState([]);
  const cartSubtotal = (cartItems.reduce(
    (subtotal, item) => subtotal + (item.VolumeDiscountValue > 0 ? item.originalPrice * (1 - (item.VolumeDiscountValue || 0)) * item.quantity : item.discountedPrice * item.quantity),
    0
  )).toFixed(2);

  useEffect(() => {
    const getShoppingCart = async () => {
      try {
        const { shoppingCart } = await getShoppingCartByID({
          userID: customerDetails?.customerID,
        });

        setCartID(shoppingCart?.id ?? 0);
        setCartItems(shoppingCart?.products ?? []);
      } catch (err) {
        console.error(err);
      }
    };

    getShoppingCart();
  }, []);

  return (
    <CartContext.Provider
      value={{
        cartID,
        cartItems,
        setCartItems,
        cartSubtotal,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
