import { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LanguageContext } from "./LanguageContext";

export const SearchBarContext = createContext(""); //context loginform

export const SearchBarProvider = ({ children }) => {
  const [result, setResult] = useState("");
  const { language } = useContext(LanguageContext)

  let navigate = useNavigate();

  const setSearchResult = (data) => {
    setResult(data);
    navigate(`/${language ? "en" : "th"}/search`);
  };

  return (
    <SearchBarContext.Provider
      value={{
        result,
        setSearchResult,
      }}
    >
      {children}
    </SearchBarContext.Provider>
  );
};
