import { useState, useContext, useEffect, useRef } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { nanoid } from "nanoid";
import LazyLoad from "react-lazy-load";
import { RiDeleteBin6Line } from "react-icons/ri";
import { AuthContext } from "../../contexts/AuthContext";
import { LanguageContext } from "../../contexts/LanguageContext";
import { ShippingAddressContext } from "../../contexts/ShippingAddressContext";
import { AddAddressModal } from "../";
import { getPersonalInfo, updatePersonalInfo } from "../../api/account";
import { addShipping, getShipping, getUserShipping, patchAllShipping } from "../../api/shipping";
import { getCountryState } from "../../api/countryState";
import { getCityArea } from "../../api/cityArea";
import { removeAddress } from "../../api/customerAddress";
import { sortAddress } from "../../utils/address";
import "./AccountTab.css";

const AccountTab = () => {
  const ref = useRef(null);
  const { language } = useContext(LanguageContext);
  const { myUserID } = useContext(AuthContext);
  const { createShipList, createAllShipList, allShipList, setAllShipList } = useContext(ShippingAddressContext);

  /** PERSONAL INFORMATION STATE */
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);
  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [gender, setGender] = useState("");
  const [birthday, setBirthday] = useState("");
  const [tax, setTax] = useState("");
  /** EDIT PERSONAL INFO TOGGLE */
  const [editPersonalInfo, setEditPersonalInfo] = useState(false);
  const [cityState, setCityState] = useState(null);
  const [citiesList, setCitiesList] = useState(null);
  //fetch AddressState Api
  const [addressStateLists, setAddressStateLists] = useState([]);
  //fetch city & suburbas Api
  const [cityAreaList, setCityAreaList] = useState([]);
  //fetch city Api
  const [addressCityLists, setAddressCityLists] = useState([]);
  const [updateAddressCityLists, setUpdateAddressCityLists] = useState([]);

  const [updateAddressSuburbsLists, setUpdateAddressSuburbsLists] = useState([]);
  const [updateAddressPostcodeLists, setUpdateAddressPostcodeLists] = useState([]);
  const [addressSuburbs, setAddressSuburbs] = useState(null);

  //state update shipping -patch
  let [updateAddressName, setUpdateAddressName] = useState(""); //--not required
  let [updateAddressPhone, setUpdateAddressPhone] = useState(""); //--not required

  let [updateAddressLine_1, setUpdateAddressLine_1] = useState("");
  let [updateAddressLine_2, setUpdateAddressLine_2] = useState("");
  let [updateAddressStateID, setUpdateAddressStateID] = useState("");
  let [updateAddressCity, setUpdateAddressCity] = useState("");
  let [updateAddressSuburbs, setUpdateAddressSuburbs] = useState("");
  let [updateAddressStateIDValue, setUpdateAddressStateIDValue] = useState(""); //--e.target.value
  let [updateAddressCityValue, setUpdateAddressCityValue] = useState(""); //--e.target.value
  let [updateAddressSuburbsValue, setUpdateAddressSuburbsValue] = useState(""); //--e.target.value

  let [updateCountry, setUpdateCountry] = useState("");
  let [updatePostcode, setUpdatePostcode] = useState("");
  let [updateDefault, setUpdateDefault] = useState(false);
  //error form select
  const [updateAddressCityError, setUpdateAddressCityError] = useState("");
  const [updateAddressSuburbsError, setUpdateAddressSuburbsError] =
    useState("");

  const [showAddAddressModal, setShowAddAddressModal] = useState(false);
  let [filterID, setFilterID] = useState("");
  let [showChange, setShowChange] = useState(false);
  let [check, setCheck] = useState();
  let [currentDate, setCurrentDate] = useState();

  //loading
  const [loading, setLoading] = useState(false);

  //getCountryState
  useEffect(() => {
    const getCountryStateApi = async () => {
      const data = await getCountryState();
      setAddressStateLists(data);
    };
    getCountryStateApi();
    getCityAreaApi();
  }, []);

  const getCityAreaApi = async () => {
    const { data } = await getCityArea();
    setCityState(data);
    setCitiesList(data);
    let jsonFilter = language ? data?.EN : data?.TH;
    setCityAreaList(jsonFilter.map((list) => list));
    setAddressCityLists(jsonFilter.map((list) => list?.CityName));
    setUpdateAddressCityLists(jsonFilter.map((list) => list?.CityName));
  };
  //getCityAreaState
  useEffect(() => {
    let jsonFilter = language ? cityState?.EN : cityState?.TH;
    setCityAreaList(jsonFilter?.map((list) => list));
    setAddressCityLists(jsonFilter?.map((list) => list?.CityName));
    setUpdateAddressCityLists(jsonFilter?.map((list) => list?.CityName));
  }, [language]);

  const selectUpdateCityName = async (e) => {
    let filterCity = updateAddressCityLists?.filter(
      (list, index) => index === JSON.parse(e.target.value)
    );

    setUpdateAddressCityValue(e.target.value);
    setUpdateAddressCity(filterCity[0]);
    generateUpdateAreaList(filterCity[0]);
    setUpdateAddressCityError(e.target.value !== "" ? "" : "required");
  };

  const generateUpdateAreaList = (cityName) => {
    const filterAreaList = cityAreaList?.filter(
      (list) => list.CityName === cityName
    );
    setUpdateAddressSuburbs(filterAreaList[0]?.AreaName[0][0]);
    setUpdateAddressSuburbsLists(filterAreaList[0]?.AreaName);
  };

  const selectUpdateSuburbsID = (e) => {
    let filterSuburb = updateAddressSuburbsLists?.filter(
      (list, index) => index === JSON.parse(e.target.value)
    );

    setUpdateAddressSuburbsValue(e.target.value);
    setAddressSuburbs(filterSuburb[0].AreaName);
    setUpdateAddressSuburbs(filterSuburb[0].AreaName);
    setUpdateAddressSuburbsError(e.target.value !== "" ? "" : "required");
    setUpdateAddressPostcodeLists(filterSuburb[0].Postcode);
  };

  //toggleOrderData
  useEffect(() => {
    //GET USER DATA
    const fetchUserData = async () => {
      setLoading(true);
      const { status, users } = await getPersonalInfo({ userID: myUserID });

      setFirstName(users["FirstName"]);
      setLastName(users["LastName"]);
      setPhoneNumber(users["PhoneNumber"]);
      setEmail(users["Email"]);
      setGender(users["Gender"]);
      setBirthday(users["Birthday"]);
      setTax(users["Tax"]);
      setLoading(false);
    };
    fetchUserData();
  }, []);

  //when save-----------------------------------------------------------------------
  const [isFormValid, setIsFormValid] = useState(true);
  const [newUserDetails, setNewUserDetails] = useState({});
  
  const onUpdate = (e) => {
    e.preventDefault()
    if (
      email.trim() === "" ||
      gender.trim() === "" ||
      firstName.trim() === "" ||
      lastName.trim() === "" ||
      birthday.trim() === "" ||
      phoneNumber.trim() === "" ||
      !isValidEmail ||
      !isPhoneNumberValid
    ) {
      return; // Stop further execution
    }

    const updatedUserDetails = {
      Email: email,
      Gender: gender,
      FirstName: firstName,
      LastName: lastName,
      Birthday: birthday,
      Phone: phoneNumber,
      Tax: tax,
    };

    setNewUserDetails(updatedUserDetails);
    setCurrentDate(new Date());
  };

  useEffect(() => {
    const updateInfo = async () => {
      const { status } = await updatePersonalInfo({
        userID: myUserID,
        userDetails: newUserDetails,
      });

      setEditPersonalInfo(false);
    };

    if (Object.keys(newUserDetails).length > 0) {
      updateInfo();
    }
  }, [newUserDetails, currentDate]);

  /**
   * Submission to add a new address for the current user
   * @param {Object} accountDetails - Account details object that is called by the AddAddressModal
   */
  const submitForm = async (accountDetails) => {
    // Add the shipping
    const { shippingID } = await addShipping({ accountDetails });
    // Get shipping api
    if (!!shippingID) {
      const data = await getShipping({ shippingID });
      if (data.Status === 200) {
        createShipList(data.customerAddressID);
        setCurrentDate(new Date());
      } else {
        setShowAddAddressModal(true);
      }
      setCurrentDate(new Date());
      setShowAddAddressModal(false);
    }
  };

  /** ------ Shipping ------ */
  const handleShowChange = async (id) => {
    setFilterID(id);
    let filterlist = allShipList.find((list) => list?.AddressID === id);

    //find result from stateID , city, suburbs == find e.target.value
    const currentAddressStateValue = addressStateLists?.findIndex(
      (list, index) => list?.stateID === filterlist?.AddressState
    );
    const currentAddressCityValue = addressCityLists?.findIndex(
      (list, index) => list === filterlist?.AddressCity
    );
    //check current arealist method
    let filterCity = addressCityLists?.filter(
      (list, index) => list === filterlist?.AddressCity
    );
    const thisCityIsTriggered = cityAreaList?.filter(
      (list) => list?.CityName === filterCity[0]
    );
    const currentAreaIsTriggeredValue =
      thisCityIsTriggered[0]?.AreaName?.findIndex(
        (list) => list === filterlist?.AddressSuburbs
      );

    setUpdateAddressName(filterlist?.AddressName);
    setUpdateAddressPhone(filterlist?.AddressPhone);
    setUpdateAddressLine_1(filterlist?.AddressLine1);
    setUpdateAddressLine_2(filterlist?.AddressLine2);
    setUpdateAddressStateID(122);
    setUpdateAddressCity(filterlist?.AddressCity);
    setUpdateAddressSuburbs(filterlist?.AddressSuburbs);
    setUpdateCountry(filterlist?.Country);
    setUpdatePostcode(filterlist?.Postcode);
    setUpdateDefault(filterlist?.ifDefault);
    //set e.target.value
    setUpdateAddressStateIDValue(122);
    setUpdateAddressCityValue(currentAddressCityValue);
    setUpdateAddressSuburbsValue(currentAreaIsTriggeredValue);
    //setAddressSuburbsLists
    setUpdateAddressSuburbsLists(thisCityIsTriggered[0]?.AreaName);
    setCurrentDate(new Date());
    setShowChange(true);
  };

  /**
   * Event handler for address that has been edited and confirmed
   * @param {Event} e - Event handler
   */
  const submitChangeHandler = async (e) => {
    e.preventDefault();
    let filterlist = allShipList.find((list) => list.AddressID === filterID);

    const newData = {
      UsersID: parseInt(myUserID),
      AddressName: updateAddressName,
      AddressPhone: updateAddressPhone,
      AddressLine_1: updateAddressLine_1,
      AddressLine_2: updateAddressLine_2,
      AddressStateID: parseInt(122),
      AddressCity: updateAddressCity,
      AddressSuburbs: updateAddressSuburbs,
      Country: updateCountry,
      Postcode: updatePostcode,
      ifDefault: updateDefault,
      AddressLanguage: language ? "EN" : "TH",
    };
    await patchAllShipping({
      shippingID: filterlist.AddressID,
      newData,
    });
    setCurrentDate(new Date());
    setShowChange(false);
  };

  //radio set default address
  const selectAddress = (e) => {
    if (e !== true) return;
    setUpdateDefault(e);
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  // Get the list of user's address shipping
  useEffect(() => {
    const fetchUserShipping = async () => {
      setLoading(true);
      const { status, data } = await getUserShipping({ userID: myUserID });

      if (status !== "200") return;
      createAllShipList(data.Shipping);

      let defaultIsTrue = data.Shipping.find((list) => list.ifDefault === true);
      setCheck(defaultIsTrue?.AddressID);
      setLoading(false);
    };

    fetchUserShipping();
  }, [check, currentDate]);

  // NEW Functions (Reinaldo)
  const handleDelete = async (id) => {
    const { data } = await removeAddress(id)
    if (data.status === "200") {
      console.log("here");
      let newAddresses = allShipList.filter((shipList) => shipList.AddressID !== id)
      console.log(newAddresses);
      setAllShipList(newAddresses);
    }
  }

  return (
    <>
      <form className="border-grey mb-4 px-3 py-4">
        <div className="data-wrap px-4">
          <Row className="mb-3 text-center">
            <Col>
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center mb-4">
                  <span>
                    <LazyLoad threshold={0}>
                      <img
                        className="d-block img-fluid img-40"
                        src={process.env.PUBLIC_URL + "/assets/ico_personal.png"}
                        alt={language ? "Personal Information" : "ข้อมูลส่วนตัว"}
                      />
                    </LazyLoad>
                  </span>
                  <h3 className="px-2 txt-bold mb-0">
                    {language ? "Personal Information" : "ข้อมูลส่วนตัว"}
                  </h3>
                </div>

                {editPersonalInfo === false ? (
                  <div
                    className="d-flex align-items-center text-end link_"
                    onClick={() => setEditPersonalInfo(!editPersonalInfo)}
                  >
                    <p className="mb-0">
                      {language ? "Edit" : "แก้ไข"}
                    </p>
                    <img
                      className="d-table ms-2 mx-auto img-fluid img-20 link_ edit_tool"
                      src={process.env.PUBLIC_URL + "/assets/checkout/ico_edit.png"}
                      alt="editPersonalInfo"
                    />
                  </div>
                ) : (
                  <div
                    className={`d-flex align-items-center text-end link_ ${
                      !isFormValid ? "disabled" : ""
                    }`}
                    onClick={(e) => onUpdate(e)}
                    disabled={!isFormValid}
                  >
                    <p
                      className={`btn btn-card-main ${
                        !isFormValid ? "disabled" : ""
                      }`}
                    >
                      {language ? "Save" : "บันทึก"}
                    </p>
                  </div>
                )}
              </div>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col sm={12} md={4}>
              <label htmlFor="name">
                {language ? "First Name" : "ชื่อจริง"}
              </label>
              <input
                type="text"
                name=""
                value={firstName ?? ""}
                disabled={!editPersonalInfo}
                onChange={(e) => setFirstName(e.target.value)}
                className="underline_input form-control mb-2"
                placeholder={language ? "First Name" : "ชื่อจริง"}
              />
            </Col>
            <Col sm={12} md={4}>
              <label htmlFor="name">
                {language ? "Last Name" : "นามสกุล"}
              </label>
              <input
                type="text"
                name=""
                value={lastName ?? ""}
                disabled={!editPersonalInfo}
                onChange={(e) => setLastName(e.target.value)}
                className="underline_input form-control mb-2"
                placeholder={language ? "Last Name" : "นามสกุล"}
              />
            </Col>
            <Col sm={12} md={4}>
              <label className="mb-1">
                {language ? "Contact Number" : "เบอร์โทรติดต่อ"}
              </label>
              <input
                type="text"
                name=""
                value={phoneNumber ?? ""}
                disabled={!editPersonalInfo}
                onChange={(e) => {
                  const enteredPhoneNumber = e.target.value;
                  setPhoneNumber(enteredPhoneNumber);
                  const phoneNumberRegex = /^[+\d]+$/; // Regular expression to allow '+' and numbers only
                  setIsPhoneNumberValid(
                    phoneNumberRegex.test(enteredPhoneNumber)
                  );
                }}
                className={`underline_input form-control mb-2 ${
                  isPhoneNumberValid ? "" : "is-invalid"
                }`}
                placeholder="+60123456789"
              />
            </Col>
            <Col sm={12} md={4}>
              <label className="mb-1">
                {language ? "Email" : "อีเมล"}
              </label>
              <input
                type="text"
                name=""
                value={email ?? ""}
                disabled={!editPersonalInfo}
                onChange={(e) => {
                  const enteredEmail = e.target.value;
                  setEmail(e.target.value);
                  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                  setIsValidEmail(emailRegex.test(enteredEmail));
                }}
                className={`underline_input form-control mb-2 ${
                  isValidEmail ? "" : "is-invalid"
                }`}
                placeholder="youremail@email.com"
              />
            </Col>
            <Col sm={12} md={4}>
              <label className="mb-1">
                {language ? "Gender" : "เพศ"}
              </label>
              <input
                type="text"
                name=""
                value={gender ?? ""}
                disabled={!editPersonalInfo}
                onChange={(e) => setGender(e.target.value)}
                className="underline_input form-control mb-2"
                placeholder={language ? "Male/Female" : "ชาย/หญิง"}
              />
            </Col>
            <Col sm={12} md={4}>
              <label className="mb-1">
                {language ? "Birthday" : "วันเดือนปีเกิด"}
              </label>
              <input
                type="text"
                name=""
                value={birthday ?? ""}
                disabled={!editPersonalInfo}
                onChange={(e) => setBirthday(e.target.value)}
                className="underline_input form-control mb-2"
                placeholder="dd/mm/yy"
              />
            </Col>
          </Row>
          <Row>
            <Col sm={24} md={8}>
              <label className="mb-1">
                {language ? "Tax Number" : "หมายเลขภาษีc"}
              </label>
              <input
                type="text"
                name=""
                value={tax ?? ""}
                disabled={!editPersonalInfo}
                onChange={(e) => setTax(e.target.value)}
                className="underline_input form-control mb-2"
                placeholder="TAX123456789"
              />
            </Col>
          </Row>
        </div>
      </form>

      <div className="border-grey mb-4 px-3 py-4">
        {/* Add Shipping Modal */}
        <AddAddressModal 
          showAddAddressModal={showAddAddressModal} 
          setShowAddAddressModal={setShowAddAddressModal} 
          submitForm={submitForm}
          citiesList={citiesList}
        />

        <Row className="mb-3" ref={ref}>
          <Col sm={12} className=" ">
            <div className="d-flex align-items-center mb-4 px-4">
              <span>
                <LazyLoad threshold={0}>
                  <img
                    className="d-block img-fluid img-40"
                    src={process.env.PUBLIC_URL + "/assets/ico_shippping_info.png"}
                    alt={language ? " Address" : "ที่อยู่สำหรับจัดส่งสินค้า"}
                  />
                </LazyLoad>
              </span>
              <h3 className="px-2 txt-bold mb-0">
                {language ? " Address" : "ที่อยู่สำหรับจัดส่งสินค้า"}
              </h3>
            </div>

            {/* showchange */}
            <form
              className="container-lg"
              onSubmit={submitChangeHandler}
            >
              <Row className="mb-3">
                <Col sm={12} className="px-4">
                  {showChange === false ? (
                    <>
                      <div className="address_group">
                        {allShipList !== undefined &&
                          sortAddress(allShipList).map((list, index) => (
                            <div
                              id={"list_" + index}
                              className="address_list px-2 px-md-3 py-4 border-bottom-1"
                              key={nanoid()}
                            >
                              <div className="row">
                                <label htmlFor="list" className="col-md-10 px-2">
                                  {list.AddressLine1},<br />
                                  {list.AddressCity},<br />
                                  {list.AddressSuburbs},<br />
                                  {list.Postcode},{list.Country}
                                </label>

                                <div className="col-md-2">
                                  <div className="d-flex h-100 flex-column justify-content-between">
                                    <div className="d-flex justify-content-end my-2">
                                      <img
                                        className="img-fluid img-20 link_ edit_tool"
                                        src={process.env.PUBLIC_URL + "/assets/checkout/ico_edit.png"}
                                        alt="editPersonalInfo"
                                        onClick={() => handleShowChange(list.AddressID)}
                                      />
                                      { !list.ifDefault && 
                                        <RiDeleteBin6Line
                                          style={{ width: "20px", height: "20px" }}
                                          className="cursor ms-2"
                                          onClick={() => handleDelete(list.AddressID)}
                                        />
                                      }
                                    </div>
                                    <div className="d-flex flex-row justify-content-end">
                                      {check === list.AddressID && (
                                        <small className="text-red-1 px-2 py-1 border-red float-end">
                                          {language ? "Default" : "ค่าเริ่มต้น"}
                                        </small>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                      {/* open modal */}
                      <div
                        className="d-flex align-items-center link_ w-100 bg-grey-6 p-3"
                        onClick={() => setShowAddAddressModal(true)}
                      >
                        <div className="d-flex align-items-center justify-content-center">
                          <span className="me-2 p-2">
                            <img
                              className="d-block img-20"
                              src={process.env.PUBLIC_URL + "/assets/checkout/ico_add.png"}
                              alt="ico"
                            />
                          </span>
                          <span>
                            {language ? "Add Address" : "เพิ่มที่อยู่สำหรับจัดส่งสินค้า"}
                          </span>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="w-100 d-flex justify-content-end px-3">
                        <div className="w-25 text-end text-decoration-underline text-orange-1 link_">
                          <button
                            type="submit"
                            className="btn btn-card-main"
                          >
                            {language ? "Confirm" : "Confirm"}
                          </button>
                        </div>
                      </div>

                      <div className="address_group">
                        <Row className="mb-3">
                          <Col sm={12} className="px-4 data_wrap">
                            <Row className="mb-4">
                              <Col sm={12}>
                                <input
                                  type="text"
                                  className="form-control mb-2"
                                  placeholder={language ? "Input address in EN*" : "ใส่ที่อยู่เป็นภาษาไทย*"}
                                  value={updateAddressLine_1}
                                  onChange={(e) => setUpdateAddressLine_1(e.target.value)}
                                />
                              </Col>
                              <Col sm={12}>
                                <input
                                  type="text"
                                  className="form-control mb-2"
                                  placeholder={language ? "Input address in EN (Optional)" : "ใส่ที่อยู่เป็นภาษาไทย"}
                                  value={updateAddressLine_2}
                                  onChange={(e) => setUpdateAddressLine_2(e.target.value)}
                                />
                              </Col>
                              <Col sm={12} md={6}>
                                <Form.Select
                                  aria-label="Default select example"
                                  className={`block form-control input-relative mb-3 ${
                                    updateAddressCityError &&
                                    "border-danger border-2 border"
                                  }`}
                                  value={updateAddressCityValue}
                                  onChange={(e) => selectUpdateCityName(e)}
                                >
                                  {!!citiesList && citiesList[language ? "EN" : "TH"]?.map(
                                    (list, index) => (
                                      <option key={index} value={index}>
                                        {list.CityName}
                                      </option>
                                    )
                                  )}
                                </Form.Select>
                                {updateAddressCityError && (
                                  <p className="errormsg">Required</p>
                                )}
                              </Col>
                              <Col sm={12} md={6}>
                                <Form.Select
                                  aria-label="Default select example"
                                  className={`block form-control input-relative mb-3 ${
                                    updateAddressSuburbsError &&
                                    "border-danger border-2 border"
                                  }`}
                                  value={updateAddressSuburbsValue}
                                  onChange={(e) => selectUpdateSuburbsID(e)}
                                >
                                  {(!!citiesList && updateAddressCityValue !== -1) && citiesList[language ? "EN" : "TH"][updateAddressCityValue].AreaName?.map(
                                    (list, index) => (
                                      <option key={index} value={index}>
                                        {list.AreaName}
                                      </option>
                                    )
                                  )}
                                </Form.Select>
                                {updateAddressSuburbsError && (
                                  <p className="errormsg">Required</p>
                                )}
                              </Col>

                              <Col sm={12} md={6}>
                                <input
                                  type="text"
                                  className="form-control mb-2"
                                  placeholder={language ? "Country*" : "ประเทศ*"}
                                  value={updateCountry}
                                  onChange={(e) => setUpdateCountry(e.target.value)}
                                />
                              </Col>
                              <Col sm={12} md={6}>
                                <Form.Select
                                  aria-label="Default select example"
                                  className={`block form-control input-relative mb-3}`}
                                  value={updatePostcode}
                                  onChange={(e) => setUpdatePostcode(e.target.value)}
                                >
                                  <option value={0} className="bg-grey-5">
                                    Select Postal Code*
                                  </option>
                                  {updateAddressPostcodeLists?.map(
                                    (list, index) => (
                                      <option key={index} value={list}>
                                        {list}
                                      </option>
                                    )
                                  )}
                                </Form.Select>
                              </Col>
                            </Row>

                            <Row>
                              <Col sm={12} md={6}>
                                <div className="d-flex align-items-center  mb-4">
                                  <input
                                    type="checkbox"
                                    name="set_default_address"
                                    onChange={(e) => selectAddress(e.target.checked)}
                                  />
                                  <label
                                    htmlFor="set_default_address"
                                    className="px-2 cus_checkbox "
                                  >
                                    {language ? "Set as default address" : "ตั้งเป็นที่อยู่สำหรับเรียกเก็บเงิน"}
                                  </label>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </>
                  )}
                </Col>
              </Row>
            </form>

            <div className="d-flex align-items-center link_ w-100 bg-grey-6 p-3 d-none">
              <div className="d-flex align-items-center justify-content-center">
                <span className="me-2 p-2">
                  <img
                    className="d-block img-20"
                    src={process.env.PUBLIC_URL + "/assets/checkout/ico_add.png"}
                    alt="ico"
                  />
                </span>
                <span>
                  {language ? "Add Address" : "เพิ่มที่อยู่สำหรับจัดส่งสินค้า"}
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default AccountTab;
