import { createContext, useEffect, useState } from "react";
import { getMetaData } from "../api/meta";
import { Helmet } from "react-helmet";

const getMeta = async () => {
  const data = await getMetaData();
  return data;
}

export const AppContext = createContext("");

export const AppProvider = ({ children }) => {
  const [metadata, setMetadata] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    Promise.resolve(getMeta()).then((res) => {
      const { data } = res;
      setMetadata(data);
    }).catch((err) => {
      console.error(err);
    })
  }, [])

  return (
    <AppContext.Provider
      value={{
        setTitle,
        setDescription
      }}
    >
      <Helmet>
        {!!title ? (
          <title>{title}{" | MR. DIY THAILAND"}</title>
        ) : (
          <title>{metadata.length > 0 && metadata[0].metaTitle}</title>
        )}
        {!!description ? (
          <meta name="description" content={description} />
        ) : (
          <meta name="description" content={metadata.length > 0 ? metadata[0].metaDescription : ""} />
        )}
        {/* {metadata.length > 0 && metadata.map((meta) => {
          return (
            <meta name={meta.metaTitle} content={meta.metaDescription} />
          )
        })} */}
      </Helmet>
      {children}
    </AppContext.Provider>
  );
};
