import { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAccessToken } from "../utils/jwt";
import { LanguageContext } from "./LanguageContext";

export const AuthContext = createContext(""); //context loginform

export const LoginProvider = ({ children }) => {
  const accessToken = getAccessToken({ parse: true });
  const { language } = useContext(LanguageContext);
  const myUserID = accessToken?.customerID;

  function epoch(date) {
    return Date.parse(date);
  }
  const date = new Date(accessToken?.exp * 1000);

  const dateToday = new Date();
  const navigate = useNavigate(); //Redirect

  const [show, setShow] = useState(false); //modal
  const [loading, setLoading] = useState(false); //modal

  const handleClose = () => setShow(false); //modal
  const handleShow = () => setShow(true); //modal

  //login
  const [jwt, setJwt] = useState("");
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");

  //signup
  const [emailOrPhone, setEmailOrPhone] = useState("");
  const [verificationType, setVerificationType] = useState("");
  const [recaptchaToken, setRecaptchaToken] = useState("");

  //code
  const [code, setCode] = useState("");

  //create username
  const [username, setUsername] = useState("");

  //create password
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [userpassword, setUserPassword] = useState("");

  const [error, setError] = useState(""); //form

  //signup
  const handleSignUp = (details, token) => {
    setEmailOrPhone(details);
    setRecaptchaToken(token);
    setTimeout(
      () => navigate((language ? "/en" : "/th") + "/user/verification"),
      300
    );
  };

  //user/verify
  const handleVerify = (details) => {
    // setCode(details);
    setTimeout(
      () => navigate((language ? "/en" : "/th") + "/user/password"),
      0
    );
  };

  //user/username
  const createUsername = (details) => {
    setUsername(details);
    setTimeout(
      () => navigate((language ? "/en" : "/th") + "/user/username"),
      0
    );
  };

  //user/password
  const createPassword = (details) => {
    setUserPassword(details);
    setTimeout(
      () => navigate((language ? "/en" : "/th") + "/user/confirmation"),
      0
    );
  };

  //logout
  const handlelogout = () => {
    localStorage.removeItem("accessToken");
    setUser("");
    setPassword("");

    setTimeout(() => {
      navigate("/");
      window.location.reload();
    }, 0);
  };

  if (date < dateToday) {
    localStorage.removeItem("accessToken");
    setTimeout(() => navigate((language ? "/en" : "/th") + "/user"), 2000);
  }

  return (
    <AuthContext.Provider
      value={{
        jwt,
        myUserID,
        user,
        setUser,
        password,
        setPassword,
        emailOrPhone,
        recaptchaToken,
        newPassword,
        verificationType,
        setVerificationType,
        show,
        setShow,
        error,
        setError,
        handleClose,
        handleShow,
        handleSignUp,
        handleVerify,
        createUsername,
        username,
        createPassword,
        handlelogout,
        loading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
