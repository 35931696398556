import { useState, useEffect, useContext } from "react";
import { Container, Col, Row } from "react-bootstrap";
import LazyLoad from "react-lazy-load";
import { RiDeleteBin6Line } from "react-icons/ri";
import {
  getWishlistItemsByCustomerID,
  moveWishlistItemToCart,
  removeWishlistItem,
} from "../../api/wishlist";
import { LanguageContext } from "../../contexts/LanguageContext";
import { AppContext } from "../../contexts/AppContext";
import { getAccessToken } from "../../utils/jwt";
import { Loader, AlertModal, WishlistItem, ContinueShoppingButton } from "../../components/";
import "./Wishlist.css";

const Wishlist = () => {
  const [wishlist, setWishlist] = useState([]);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentDate, setCurrentDate] = useState();
  const { language } = useContext(LanguageContext);
  const { setTitle, setDescription} = useContext(AppContext);

  useEffect(() => {
    const getWishlist = async () => {
      try {
        setLoading(true);
        const userDetails = getAccessToken({ parse: true });

        const { wishlist: wishlistData } = await getWishlistItemsByCustomerID({
          customerID: userDetails.customerID,
        });

        setWishlist(
          wishlistData?.map((data) => ({ ...data, checked: false })) ?? []
        );
        setTitle("Wishlist")
        setDescription("")
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    getWishlist();
  }, [currentDate]);

  //checkbox
  const handleSelectAll = (e) => {
    setWishlist(
      wishlist.map((item) => ({ ...item, checked: e.target.checked }))
    );
  };

  const handleClick = (itemId) => {
    setWishlist(
      wishlist.map((item) => {
        if (item.itemId === itemId) {
          return { ...item, checked: !item.checked };
        }

        return item;
      })
    );
  };

  const removeSelectedCartItem = async () => {
    try {
      setLoading(true);
      const selectedItemIds = wishlist
        .filter((item) => item.checked)
        .map((item) => item.itemId);

      const promises = [];
      selectedItemIds.forEach((itemId) => {
        promises.push(removeWishlistItem(itemId));
      });

      const responses = await Promise.all(promises);

      if (responses.every((response) => response.status === 200)) {
        setWishlist(
          wishlist.filter((item) => !selectedItemIds.includes(item.itemId))
        );
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const addToCart = async (id, productID) => {
    try {
      setLoading(true);

      const customerDetails = getAccessToken({ parse: true });

      const { status } = await moveWishlistItemToCart({
        id,
        productID,
        customerID: customerDetails.customerID,
      });
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
      setCurrentDate(new Date());
    }
  };

  let globalIMG = process.env.PUBLIC_URL;

  return (
    <div className="wishlist_page px-2 px-md-4 px-lg-5">
      {loading && <Loader status={loading} />}

      <AlertModal
        show={show}
        handleClose={() => setShow(false)}
        message="Please select item(s)"
      />

      <Container fluid="xxl" className="mb-1">
        <Row className="data_wrap pt-5 px-2 px-md-5">
          <ContinueShoppingButton />
          <Col xs={12}>
            <div className="d-flex align-items-center">
              <span>
                <LazyLoad threshold={0}>
                  <img
                    className="d-block img-fluid img-70"
                    src={globalIMG + "/assets/ico_page_wishlist.png"}
                    alt="basket"
                  />
                </LazyLoad>
              </span>
              <h2 className="px-2 txt-bold txt-super-bold mb-0">
                {language ? "My Wishlist" : "สินค้าที่ฉันอยากได้"}
              </h2>
            </div>
          </Col>
        </Row>
      </Container>

      {wishlist?.length !== 0 ? (
        <Container fluid="xxl" className="mb-5  px-2 px-md-5">
          <Row
            className={`pt-5 mb-3  ${
              wishlist?.length !== 0 ? "d-block" : "d-none"
            }`}
          >
            <Col>
              <div className="d-flex align-items-center">
                <input
                  type="checkbox"
                  className="form-check-input"
                  name="select"
                  id="select"
                  onChange={handleSelectAll}
                  checked={wishlist.every((item) => item.checked)}
                />
                <label htmlFor="select" className="px-2 cus_checkbox">
                  {language ? "Select All |" : "เลือกทั้งหมด |"}
                </label>
                <RiDeleteBin6Line
                  className="cursor"
                  onClick={() => removeSelectedCartItem()}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              {wishlist?.map((product) => (
                <WishlistItem
                  key={product.itemId}
                  product={product}
                  handleClick={handleClick}
                  error={""}
                  addToCart={addToCart}
                />
              ))}
            </Col>
          </Row>
        </Container>
      ) : (
        <Container fluid="xxl" className="mb-5">
          <Row className="px-2 px-md-5">
            <Col>
              <div className="bg-grey-4  p-5 text-center errormsg mb-5">
                Your wishlist is empty
              </div>
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
}

export default Wishlist;
