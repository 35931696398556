import axios from "axios";
import { getAccessToken } from "../utils/jwt";
import { checkAccessToken } from "./interceptor";

export const addressClient = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_ENDPOINT}/CustomerAddress`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${getAccessToken()}`,
  },
});

addressClient.interceptors.request.use(checkAccessToken);

export const getAddress = async ({ addressID }) => {
  const { data } = await addressClient.get(`/${addressID}`);
  const { Status } = data;

  return {
    status: Status,
  };
};

export const removeAddress = async (addressID) => {
  const { data } = await addressClient.delete(`/${addressID}`);
  return {
    data
  }
}
