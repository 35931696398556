import axios from "axios";

const productClient = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_ENDPOINT}/search`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export const searchProducts = async (productKey) => {
  try {
    const { data } = await productClient.post("", {
      query: productKey,
      table: "Products",
    });

    return {
      result: data?.data,
      status: data?.status,
    };
  } catch (err) {
    console.error(err);
  }
};
