import { useEffect, useContext, useState, memo } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { LanguageContext } from "../../contexts/LanguageContext";
import { getCategories } from "../../api/category";
import { convertToURLParams } from "../../utils/string";
import "./ProductCategories.css";

const content = {
  title: {
    EN: "Categories",
    TH: "ประเภทสินค้า",
  },
};

const ProductCategories = () => {
  const [categories, setCategories] = useState([]);
  const { language: lang } = useContext(LanguageContext);
  const language = lang ? "EN" : "TH";

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const { data } = await getCategories(lang);
        setCategories(data);
      } catch (err) {
        console.error(err)
      }
    };
    fetchCategories();
  }, [lang])

  return (
    <Container fluid="xl" className="py-4 py-lg-5">
      <Row className="justify-content-center text-center">
        <Col>
          <h2 className="title-center">{content.title[language]}</h2>
        </Col>
      </Row>

      <Row className="justify-content-center px-2 px-sm-5 px-lg-0">
        {categories.length > 0
          ? categories.map(({ id, name, thaiName, image }) => {
            return (
              <Col xs={3} md={"auto"} key={id}>
                <div className="ico_sec d-table mx-auto my-3 text-center px-md-3 px-0">
                  <Link to={`${convertToURLParams(lang ? name : thaiName)}-cat.${btoa(id)}`}>
                    <div className="ico_rounded mb-3">
                      <img src={image} alt={`${name.toLowerCase()}`} />
                    </div>

                    <h6>{lang ? name : thaiName}</h6>
                  </Link>
                </div>
              </Col>
            )})
          : null}
      </Row>
    </Container>
  );
}

export default memo(ProductCategories);
