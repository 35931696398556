import { Modal } from "react-bootstrap";
import { VscClose } from "react-icons/vsc";

function AlertModal({ show, handleClose, message }) {
  return (
    <Modal
      size="lg"
      centered
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Body>
        <div className="ProductDetail_Panel_Box" onClick={handleClose}>
          <VscClose className="vs_close" />
        </div>
        <div className="py-5">
          <p className="text-center text-red-1">{message}</p>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default AlertModal;
