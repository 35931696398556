import { createContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

export const SortProductContext = createContext("name"); //context loginform

export const SortProductProvider = ({ children }) => {
  //parent :path
  const location = useLocation();
  const pageLocated = location.pathname + location.search; // exp : products?tab=new

  const [point, setPoint] = useState("");

  const handlesort = (e) => {
    setPoint(e.target.value);
  };

  useEffect(() => {
    if (pageLocated === "/tab/new") {
      setPoint("new");
    } else if (pageLocated === "/tab/bestselling") {
      setPoint("bestSeller");
    } else if (pageLocated === "/tab/onsale") {
      setPoint("onSale");
    } else {
      setPoint("");
    }
  }, [pageLocated]);

  return (
    <SortProductContext.Provider value={{ point, handlesort }}>
      {children}
    </SortProductContext.Provider>
  );
};
