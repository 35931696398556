import { useState, useContext } from "react";
import { nanoid } from "nanoid";
import LazyLoad from "react-lazy-load";
import { BsChevronDoubleUp, BsChevronDoubleDown } from "react-icons/bs";
import { LanguageContext } from "../../contexts/LanguageContext";

/**
 * The lists of all purchase histories of a user
 * @param {Object} props - The props for PurchaseHistoryList component
 * @param {Object} props.data - The data that has OrderItems array in it
 * @param {Function} props.invoiceModalHandler - Function to toggle the modal exists from the parent component
 * @returns {JSX.Element} - The Lists of Purchase Histories
 */
const PurchaseHistoryList = ({ data, invoiceModalHandler }) => {
  const { language } = useContext(LanguageContext);
  const [show, setShow] = useState(false);

  const toggleOrderData = (resultID) => {
    if (resultID === data.OrderID) {
      setShow(!show);
    }
  };

  const ButtonShowMore = () => {
    return (
      <div
        className="link_ mb-4 mb-lg-0 ms-3"
        onClick={() => toggleOrderData(data.OrderID)}
      >
        <p className="mb-0 text-danger txt-15 lh-1">
          {show ? (
            <>
              {language ? "Show Less" : "แสดงน้อยลง"} <BsChevronDoubleUp />
            </>
          ) : (
            <>
              {language ? "Show More" : "แสดงมากขึ้น"} <BsChevronDoubleDown />
            </>
          )}
        </p>
      </div>
    );
  };

  return (
    <>
      {!!data &&
        data.OrderItems?.map((item, index) => (
          <div
            id={"data" + item.SKUID}
            key={nanoid()}
            className={`py-2 cart_Row d-flex ${
              data.OrderItems?.length === index + 1 ? "" : "border-bottom-1"
            } ${show ? "d-block" : "d-none"}`}
          >
            <div className="cart_Col2 cart_Col">
              <img src={item.Image} alt="" className="img-fluid" />
            </div>

            <div className="cart_Col3 cart_Col">
              <div className="d-lg-flex pt-2 w-100 justify-content-between mb-3">
                <h5 className="wid-50 mb-3">
                  {language ? item.OrderItemSKUName[0].EN : item.OrderItemSKUName[1].TH}
                </h5>

                <div className="d-flex align-items-lg-start text-end w-100 justify-content-lg-center ">
                  <h4 className="text-price d-flex txt-20 mb-2 text-center">
                    <div className="mb-3 me-2">
                      {language ? " Qty" : " จำนวน"}
                    </div>
                    <div className="">{item.UnitCounts}</div>
                  </h4>
                </div>

                <h3 className="text-price txt-30 mb-2 text-end">
                  <sub>THB</sub>{item.TotalPrice}
                </h3>
              </div>
            </div>
          </div>
        ))}
      <div className="d-flex justify-content-between justify-content-lg-end py-3 align-items-end border-top-1 mt-3">
        <div className="d-lg-flex align-items-end">
          <div className="d-none d-lg-flex align-items-center">
            <span className="">
              <LazyLoad threshold={0}>
                <img
                  className="d-block img-fluid img-20 lh-1"
                  src={process.env.PUBLIC_URL + "/assets/ico_print.png"}
                  alt="invoice"
                />
              </LazyLoad>
            </span>
            {data.OrderStatus === "Completed" ||
             data.OrderStatus === "Processing" ? (
              <div
                className="px-2 link_ mb-0 me-3 lh-1"
                onClick={() => invoiceModalHandler(data.OrderID)}
              >
                {language ? "Print Invoice" : "พิมพ์ใบแจ้งหนี้"}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="d-lg-flex text-end align-items-lg-end">
          <ButtonShowMore />
          <div className="ms-4 d-lg-flex  align-items-lg-end">
            <p className="me-2 mb-0 lh-1">
              {language ? "Total:" : "ราคารวมทั้งหมด:"}
            </p>

            <h3 className="text-price-h4 txt-35 mb-0 lh-1">
              <sub className="sub-price-h4">THB</sub>
              {data.OrderTotalPrice}
            </h3>
          </div>
        </div>
      </div>
    </>
  );
}

export default PurchaseHistoryList;
