import { useState, useContext, useEffect, memo } from "react";
import { Container, Row, Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import { LanguageContext } from "../../contexts/LanguageContext";
import { getProductsByTag } from "../../api/product";
import { PaginationGroup, ProductCard } from "../";
import { DISPLAY_ITEMS_PER_PAGE as TOTAL_ITEMS_DISPLAY } from "../../constants/config";
import "./ProductTags.css";

/**
 * Display the product per tags
 * @returns {JSX.Element} - The Product Tab Section component
 */
const ProductTabSec = () => {
  const { language } = useContext(LanguageContext);
  const [tags, setTags] = useState([]);
  const [products, setProducts] = useState({});
  const [currentTag, setCurrentTag] = useState("");
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const { products } = await getProductsByTag("all");
        const tags = Object.keys(products);
    
        setTags(tags);
        setCurrentTag(tags[0]);
        setProducts(products);
      } catch(err) {
        console.error(err)
      }
    };
    fetchProducts();
  }, []);

  return (
    <div className="py-5">
      <Tabs
        onSelect={(e) => setCurrentTag(e)}
        defaultActiveKey={tags[0]}
        id="fill-tab-example"
        variant="tabs"
        className="justify-content-center"
        justify
      >
        {tags.map((tag) => (
          <Tab key={tag} eventKey={tag} title={language ? tag : tag}>
            <Container fluid="xxl">
              <Row className="d-flex data_wrap">
                {!products || products[tag].length <= 0 ? (
                  <span className="errormsg text-center txt-30">
                    No Result Found
                  </span>
                ) : (
                  <>
                    {products[tag].slice(
                      TOTAL_ITEMS_DISPLAY * (pageNumber-1),
                      TOTAL_ITEMS_DISPLAY * pageNumber).map((product, idx) => (
                        <ProductCard key={idx} product={product} categoryName={product.category} />
                    ))}
                    <PaginationGroup 
                      pageNumber={pageNumber} 
                      setPageNumber={setPageNumber} 
                      totalItems={products[tag].length} 
                      totalItemsDisplay={TOTAL_ITEMS_DISPLAY} 
                    />
                  </>
                )}
              </Row>

              <Row>
                <Link
                  to={`products/tags/${currentTag
                    .toString()
                    ?.toLowerCase()
                    .split(" ")
                    ?.join("-")}`}
                >
                  <button className="btn-group bg-yellow-2 btn-card-main d-table mx-auto">
                    {language ? "See More" : "ดูเพิ่มเติม"}
                  </button>
                </Link>
              </Row>
            </Container>
          </Tab>
        ))}
      </Tabs>
    </div>
  );
}

export default memo(ProductTabSec);
