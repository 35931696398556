import { useState, useEffect, useContext, memo } from "react";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { VscClose } from "react-icons/vsc";
import LazyLoad from "react-lazy-load";
import Parser from "html-react-parser";
import { PurchaseHistoryList } from "../";
import { generateInvoice } from "../../api/invoice";
import { getUserOrders } from "../../api/order";
import { LanguageContext } from "../../contexts/LanguageContext";
import { AuthContext } from "../../contexts/AuthContext";

/**
 * Tab child component in /account
 * @returns {JSX.Element}
 */
const PurchaseHistoryTab = () => {
  const [invoiceHtml, setInvoiceHtml] = useState("");
  const [orderData, setOrderData] = useState([]);
  const [showInvoiceModal, setShowInvoiceModal] = useState(false);
  const { language } = useContext(LanguageContext)
  const { myUserID } = useContext(AuthContext);

  // Print invoice with div
  const printDiv = () => {
    let divContents = document.getElementsByClassName("invoiceBox")[0].innerHTML;

    var a = window.open("");
    a.document.write(divContents);

    a.print();
    a.close();
    setShowInvoiceModal(!showInvoiceModal);
  };

  // Open invoice modal
  const invoiceModalHandler = async (orderID) => {
    const { status, orderInvoice } = await generateInvoice({
      orderID: orderID,
      language: language ? "en" : "th",
    });
    if (status === undefined || status === 500) return;
    setShowInvoiceModal(!showInvoiceModal);
    setInvoiceHtml(orderInvoice);
  };

  useEffect(() => {
    const fetchOrderStatus = async () => {
      const { status, orders } = await getUserOrders({ userID: myUserID });
      if (status !== "200") return;
      setOrderData(orders.data);
    };
    fetchOrderStatus();
  }, [])

  return (
    <div className="purchaseHistorySec">
      <div className="d-flex data_wrap row">
        {orderData.length <= 0 && (
          <span className="errormsg text-center txt-30">
            No Result Found
          </span>
        )}
      </div>

      {/* invoice modal */}
      <Modal
        fullscreen={true}
        show={showInvoiceModal}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body>
          {/* add single shipping list */}
          {/* createShipList */}
          <div
            className="Invoice_Panel_Box"
            onClick={() => setShowInvoiceModal(false)}
          >
            <VscClose className="vs_close" />
          </div>
          <div className="d-block mb-5"></div>
          {/* submit single shipping list */}
          <div className="invoiceBox p-3 mb-4 ">
            {Parser(invoiceHtml)}
          </div>
          <div
            className="btn-card-sec btn-group-vertical link_ d-table m-auto"
            onClick={() => printDiv()}
          >
            {language ? "Print Invoice" : "พิมพ์ใบแจ้งหนี้"}
          </div>
        </Modal.Body>
      </Modal>

      {orderData?.map((data, index) => (
        <div
          key={index}
          className={`border-grey mb-4 mb-md-5 px-3 py-4 ${
            data.OrderStatus === "Cancelled" && "bg-grey-5"
          }`}
        >
          <div className="d-flex align-items-center justify-content-between pb-3 border-bottom-1">
            <div className="d-flex align-items-center">
              <span>
                <LazyLoad threshold={0}>
                  <img
                    className="d-block img-fluid img-40"
                    src={process.env.PUBLIC_URL + "/assets/ico_delivered.png"}
                    alt="delivered"
                  />
                </LazyLoad>
              </span>
              <div>
                <p className="px-2 txt-bold mb-0">
                  {language
                    ? data.OrderCompletionDate != ""
                      ? "Delivered on " + data.OrderCompletionDate
                      : data.OrderTrackingNumner != ""
                      ? "Tracking Number: " + data.OrderTrackingNumner
                      : ""
                    : data.OrderCompletionDate != ""
                    ? "จัดส่งแล้ว เมื่อ " + data.OrderCompletionDate
                    : data.OrderTrackingNumner != ""
                    ? "หมายเลขติดตาม: " + data.OrderTrackingNumner
                    : ""}
                </p>
                <Link className="link" to={`/${language ? "en" : "th"}/orders/${btoa(data.OrderID)}`}>
                  <small className="px-2 txt-bold text-orange-1 d-block mb-0 ">
                    {language ? "Order ID" : "คำสั่งซื้อเลขที่"} :{" "}
                    {data.OrderID} {language ? "Date" : "วันที่"} :{" "}
                    {data.OrderDateString}
                  </small>
                </Link>
              </div>
              {!!data.OrderTrackingNumner && (
                <div>
                  <a
                    href="https://www.jtexpress.co.th/service/track"
                    target="_blank"
                    rel="noreferrer"
                  >
                    ติดตามสถานะการจัดส่ง [Parcel Tracking]
                  </a>
                </div>
              )}
            </div>

            <div>
              {data.OrderStatus === "New" && (
                <small className="bg-orange text-white px-2 py-1  mb-0">
                  {language ? "New" : "ใหม่"}
                </small>
              )}
              {data.OrderStatus === "Completed" && (
                <small className="bg-green-2 text-white px-2 py-1  mb-0">
                  {language ? "Completed" : "สำเร็จแล้ว"}
                </small>
              )}
              {data.OrderStatus === "Cancelled" && (
                <small className="bg-red-1 text-white px-2 py-1  mb-0">
                  {language ? "Cancelled" : "ยกเลิกแล้ว"}
                </small>
              )}
              {data.OrderStatus === "Pending" && (
                <small className="bg-grey-3 text-white px-2 py-1  mb-0">
                  {language ? "Pending" : "รอดำเนินการ"}
                </small>
              )}
              {data.OrderStatus === "Processing" && (
                <small className="bg-orange text-white px-2 py-1  mb-0">
                  {language ? "Processing" : "กำลังประมวลผล"}
                </small>
              )}
            </div>
          </div>

          <PurchaseHistoryList
            data={data}
            invoiceModalHandler={invoiceModalHandler}
          />
        </div>
      ))}
    </div>
  )
}

export default memo(PurchaseHistoryTab);