import { useState, useEffect, useContext } from "react";
import TagManager from "react-gtm-module";
import { Container, Row, Col } from "react-bootstrap";
import LazyLoad from "react-lazy-load";
import { Link } from "react-router-dom";
import { RiDeleteBin6Line } from "react-icons/ri";
import { removeSingleShoppingCart, setCartItem } from "../../api/shoppingCart";
import { LanguageContext } from "../../contexts/LanguageContext";
import { CartContext } from "../../contexts/CartContext";
import { AppContext } from "../../contexts/AppContext";
import {
  ProductItem,
  ContinueShoppingButton,
  AlertModal,
} from "../../components";
import { getAccessToken } from "../../utils/jwt";
import "./Cart.css";

const Cart = () => {
  const customerDetails = getAccessToken({ parse: true });
  const userID = customerDetails?.customerID;
  const { language } = useContext(LanguageContext);
  const { cartID, cartItems, setCartItems, cartSubtotal } =
    useContext(CartContext);
  const { setTitle, setDescription } = useContext(AppContext);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [selectedCartItems, setSelectedCartItems] = useState([]);
  const [show, setShow] = useState(false);
  const [errorID, setErrorID] = useState("");

  useEffect(() => {
    const tagManagerArgs = {
      gtmId: "GTM-MMFHWKW",
      events: {
        view_cart: "view_cart",
      },
    };

    TagManager.initialize(tagManagerArgs);
    setTitle("Shopping Cart");
    setDescription("");
  }, []);

  const toggleSelectAll = () => {
    setIsSelectAll(!isSelectAll);
    setSelectedCartItems(isSelectAll ? [] : cartItems.map((item) => item.id));
  };

  const handleCartItemSelect = (e, cartItemID) => {
    const isSelected = e.target.checked;

    if (isSelected) {
      setSelectedCartItems((selectedCartItems) => [
        ...selectedCartItems,
        cartItemID,
      ]);
    } else {
      setSelectedCartItems((selectedCartItems) =>
        selectedCartItems.filter((item) => item !== cartItemID)
      );
    }
  };

  const updateCartItemQuantity = async (id, quantity) => {
    await setCartItem({
      customerID: userID,
      productID: id,
      quantity,
    }).then((res) => {
      if (!res.ErrorMsg) {
        let volumeDiscounts = res.data;
        let volumeDiscountValue = 0;
        setCartItems((cartItems) => {
          volumeDiscounts?.map((a) => {
            if (id == a.SKUID) {
              volumeDiscountValue =
                parseFloat(a.VolumeDiscountValue) / 100 || 0;
            }
          });
          return cartItems
            .map((item) =>
              item.id === id
                ? {
                    ...item,
                    quantity: quantity,
                    VolumeDiscountValue: volumeDiscountValue,
                  }
                : item
            )
            .filter((item) => item.quantity > 0);
        });
      }
    });
  };

  const removeSelectedCartItem = async () => {
    if (selectedCartItems.length <= 0) {
      setShow(true);
      return;
    }

    try {
      await Promise.all(
        selectedCartItems.map((cartItemID) =>
          removeSingleShoppingCart(cartID, {
            customerID: userID,
            productID: cartItemID,
            quantity: 0,
          })
        )
      );

      setCartItems((cartItems) =>
        cartItems.filter((item) => !selectedCartItems.includes(item.id))
      );
      setSelectedCartItems([]);
      setIsSelectAll(false);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="cart_page px-2 px-md-5">
      <AlertModal
        show={show}
        handleClose={() => setShow(false)}
        message="Please select item(s)"
      />

      <Container fluid="xxl" className="mb-1">
        <Row className="data_wrap pt-5 px-2 px-md-5">
          <ContinueShoppingButton />

          <Col xs={12}>
            <div className="d-flex align-items-center">
              <span>
                <LazyLoad threshold={0}>
                  <img
                    className="d-block img-fluid img-70"
                    src="/assets/ico_page_basket.png"
                    alt="basket"
                  />
                </LazyLoad>
              </span>
              <h2 className="px-2 txt-bold txt-super-bold mb-0">
                {language ? "My Shopping Cart" : "ตะกร้าของฉัน"}
              </h2>
            </div>
          </Col>
        </Row>
      </Container>

      {cartItems.length !== 0 ? (
        <Container fluid="xxl" className="mb-5  px-2 px-md-5">
          <Row
            className={`pt-5 mb-3  ${
              cartItems.length !== 0 ? "d-block" : "d-none"
            }`}
          >
            <Col>
              <div className="d-flex align-items-center">
                <input
                  type="checkbox"
                  id="select"
                  name="select"
                  className="form-check-input"
                  onChange={toggleSelectAll}
                  checked={isSelectAll}
                />
                <label htmlFor="select" className="px-2 cus_checkbox">
                  {language ? "Select All |" : "เลือกทั้งหมด |"}
                </label>
                <RiDeleteBin6Line
                  style={{ width: "20px", height: "20px" }}
                  className="cursor"
                  onClick={removeSelectedCartItem}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              {cartItems.map((item) => (
                <ProductItem
                  key={item.id}
                  product={item}
                  handleCartItemSelect={handleCartItemSelect}
                  selectedCartItems={selectedCartItems}
                  updateCartItemQuantity={updateCartItemQuantity}
                  error={errorID}
                />
              ))}
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <div className="d-flex  w-100 justify-content-end ">
                <div className="total_group mb-4">
                  <div className="d-flex justify-content-end align-items-center text-end">
                    <p className="txt-30 me-2">
                      {language ? "Sub-total" : "ราคารวม"}
                    </p>
                    <h2>
                      <sub className="sub-price-h2">THB</sub>
                      {cartSubtotal}
                    </h2>
                  </div>
                  <small>
                    {language
                      ? "(Prices shown include VAT, exclude shipping fee)"
                      : "(ราคาที่แสดงรวมภาษีมูลค่าแล้ว โดยยังไม่ได้รวมค่าจัดส่ง)"}
                  </small>
                </div>
              </div>
            </Col>
            <Col xs={12}>
              <Link
                className="link_ btn-card-sec d-table ms-auto mb-4"
                to={`/${language ? "en" : "th"}/checkout`}
              >
                {language ? "Checkout Now" : "ชำระเงิน"}
              </Link>
            </Col>
          </Row>
        </Container>
      ) : (
        <Container fluid="xxl" className="mb-5">
          <Row className="px-2 px-md-5">
            <Col>
              <div className="bg-grey-4  p-5 text-center errormsg mb-5">
                Your shopping cart is empty
              </div>
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
};

export default Cart;
