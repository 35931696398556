import { Carousel } from "react-bootstrap";
import { useContext, useEffect, useState } from "react";
import { getBanners } from "../../api/banners";
import { LanguageContext } from "../../contexts/LanguageContext";
import "./Hero.css";

function Hero() {
  const [banners, setBanners] = useState([]);
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    const fetchBanners = async () => {
      try {
        const { banners } = await getBanners(language);
        setBanners(banners);
      } catch (err) {
        console.error(err);
      }
    };
    fetchBanners();
  }, [language]);

  return (
    <Carousel fade touch controls={false} id="carousel_div">
      {banners.map((banner, index) => (
        <Carousel.Item key={index}>
          <a href={banner.bannerHref} target="_blank">
            <img
              className="d-block banner__image"
              src={banner.bannerImageURL}
              alt="First slide"
            />
          </a>
        </Carousel.Item>
      ))}
    </Carousel>
  );
}

export default Hero;
