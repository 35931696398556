import { useContext } from "react";
import { Col } from "react-bootstrap";
import { HiChevronLeft } from "react-icons/hi";
import { Link } from "react-router-dom";
import { LanguageContext } from "../../contexts/LanguageContext";

function ContinueShoppingButton() {
  const { language } = useContext(LanguageContext);

  return (
    <Col xs={12}>
      <Link
        to="/"
        className="link_ d-table bg-grey-5 me-0 me-md-3 p-2 mb-4 border-rounded"
      >
        <div className="d-flex align-items-center">
          <span className="me-2 bg-yellow-1 box-40 d-flex align-items-center justify-content-center border-rounded">
            <HiChevronLeft className="hiCheveronLeft" />
          </span>
          <span className="px-2 txt-bold">
            {language ? "Continue Shopping" : "เลือกซื้อสินค้าต่อ"}
          </span>
        </div>
      </Link>
    </Col>
  );
}

export default ContinueShoppingButton;
