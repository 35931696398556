/**
 * Sort the default address to be the first in index
 * @param {Array} addresses - List of addresses
 * @return {Array} addresses
 */
export const sortAddress = (addresses) => {
  const trueDefaultIndex = addresses.findIndex(item => item.ifDefault === true);
  if (trueDefaultIndex !== -1) {
      const trueDefaultElement = addresses.splice(trueDefaultIndex, 1)[0];
      addresses.unshift(trueDefaultElement);
  }
  return addresses;
}