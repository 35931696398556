import { useContext, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { BsList, BsSearch } from "react-icons/bs";
import LanguageToggle from "../../shared/language/LanguageToggle";
import { Link, useLocation } from "react-router-dom";
import Logout from "../../login/Logout";
import "./Navbar.css";
import CategoryPanel from "./CategoryPanel";
import { AuthContext } from "../../../contexts/AuthContext";
import { LanguageContext } from "../../../contexts/LanguageContext";
import { SearchBarContext } from "../../../contexts/SearchBarContext";
import { searchProducts } from "../../../api/search";
import { CartContext } from "../../../contexts/CartContext";

function CusNavbar() {
  const { language } = useContext(LanguageContext);
  const { myUserID } = useContext(AuthContext);
  const { setSearchResult } = useContext(SearchBarContext);
  const { cartItems } = useContext(CartContext);

  const [searchProduct, setSearchProduct] = useState("");
  const [menuOpen, setMenuOpen] = useState(false);

  let globalIMG = process.env.PUBLIC_URL;

  const location = useLocation();
  const pageLocated = location.pathname;

  let auth = myUserID;

  const submitSearchHandler = async (searchProduct) => {
    try {
      const { result, status } = await searchProducts(searchProduct);
      if (searchProduct === "" || status === 500) {
        setSearchResult([]);
        return;
      } else {
        setSearchResult(result);
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <CategoryPanel open={menuOpen} setOpen={setMenuOpen} />

      <div
        className={`App-header bg-yellow-1 sticky-top ${
          pageLocated === "/admin" || pageLocated === "/admin/dashboard"
            ? "d-none"
            : "d-block"
        }`}
        id="myHeader"
      >
        <Container fluid="xxl" className="app_header">
          <Row className="py-2 px-2 px-lg-3 align-items-center">
            <Col
              className="py-2 px-2  text-center order-3 col-3 col-3-cus"
              lg={{ span: 1, order: 0 }}
            >
              {pageLocated === "/user" ? (
                ""
              ) : (
                <span onClick={() => setMenuOpen(true)}>
                  <BsList className="txt-50 link_" />
                </span>
              )}
            </Col>

            <Col className="py-2 order-1 col-12" lg={{ span: 3, order: 1 }}>
              <section className="d-flex justify-content-between align-items-center language_box">
                <Link to={`/${language ? "en" : "th"}`}>
                  <img
                    className="img-fluid img-65"
                    src={globalIMG + "/logo_diy.png"}
                  />
                </Link>
                <LanguageToggle />
              </section>
            </Col>

            <Col
              className="py-2  text-center order-5 col-12 col-4-cus"
              lg={{ span: 4, order: 2 }}
            >
              {pageLocated === "/user" ? (
                ""
              ) : (
                <div className="input_search">
                  <input
                    className="form-control"
                    type="search"
                    placeholder={
                      language
                        ? "Search by product, brand, SKU"
                        : "ค้นหาด้วยชื่อสินค้า ยี่ห้อ หรือประเภทสินค้า"
                    }
                    value={searchProduct}
                    onChange={(e) => setSearchProduct(e.target.value)}
                  />
                  <div
                    className="box_search bg-red-1 link_"
                    onClick={() => submitSearchHandler(searchProduct)}
                  >
                    <BsSearch className="btn_search" />
                  </div>
                </div>
              )}
            </Col>

            {pageLocated === "/user" ? (
              ""
            ) : (
              <>
                <Col
                  className="py-2 px-2  text-center order-3 col-3 col-3-cus"
                  lg={{ span: 1, order: 3 }}
                >
                  <Link to={`${language ? "en" : "th"}/wishlist`}>
                    <img
                      className="img-fluid img-50"
                      src="/assets/ico_wishlist.png"
                    />
                  </Link>
                </Col>

                <Col
                  className="py-2 px-2 text-center order-3 col-3 col-3-cus"
                  lg={{ span: 1, order: 3 }}
                >
                  <Link to={`${language ? "en" : "th"}/cart`}>
                    <div className="cart_car">
                      <img
                        className="img-fluid img-50"
                        src="/assets/ico_cart.png"
                      />
                      <div className="cart_box bg-red-1">
                        <span className="text-light">{cartItems.length}</span>
                      </div>
                    </div>
                  </Link>
                </Col>

                <Col
                  className="py-2 px-2 text-center order-4 col-3 col-6-cus"
                  lg={{ span: 2, order: 4 }}
                >
                  {auth === null || auth === undefined ? (
                    <>
                      <section className="d-flex align-items-center justify-content-center">
                        <Link to={`${language ? "en" : "th"}/user`} className="d-flex">
                          <img
                            className="img-fluid img-50 me-2"
                            src={globalIMG + "/assets/ico_login.png"}
                          />
                        </Link>
                        <div className="text-start d-none d-xl-block ms-2">
                            <Link to={`${language ? "en" : "th"}/user`} className="d-flex">
                              <span className="d-block txt-bold">
                                {language ? "Sign In" : "ลงชื่อเข้าใช้"}
                              </span>
                            </Link>
                            <Link to={`${language ? "en" : "th"}/user/signup`} className="d-flex">
                              <span className="d-block">{language ? "Register" : "ลงทะเบียน"}</span>
                            </Link>
                        </div>
                      </section>
                    </>
                  ) : (
                    <Logout />
                  )}
                </Col>
              </>
            )}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default CusNavbar;
