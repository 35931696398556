import { createContext, useState, useEffect } from "react";
import { Cookies } from '../components/'
export const CookiesContext = createContext("cookies");

export const CookiesProvider = ({ children }) => {
  const [cookies, setCookies] = useState(false);

  useEffect(() => {
    const savedCookies = JSON.parse(localStorage.getItem("cookies"));
    setCookies(savedCookies ?? false);
  }, [cookies]);

  const handleCookies = () => {
    setCookies(!cookies);
    localStorage.setItem("cookies", JSON.stringify(!cookies));
  };

  return (
    <CookiesContext.Provider
      value={{
        cookies,
        handleCookies,
      }}
    >
      {children}
      {cookies || <Cookies/>}
    </CookiesContext.Provider>
  );
};
