import { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AuthContext } from "./contexts/AuthContext";
import { LanguageContext } from "./contexts/LanguageContext";

const VerifyRoute = () => {
  const { emailOrPhone } = useContext(AuthContext);
  const { language } = useContext(LanguageContext);

  return emailOrPhone !== "" ? (
    <Outlet />
  ) : (
    <Navigate to={`${language ? "/en" : "/th"}/signup`}></Navigate>
  );
}

export default VerifyRoute;
