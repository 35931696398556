import { useLayoutEffect } from "react";
import { SpinnerCircular } from "spinners-react";

const Loader = ({ status }) => {
  useLayoutEffect(() => {
    if (status === "true") {
      document.body.style.overflow = "hidden";
      document.body.style.height = "100vh";
    }
    if (status === "false") {
      document.body.style.overflow = "auto";
      document.body.style.height = "auto";
    }
  }, [status]);

  return (
    <div className="blackScreen">
      <SpinnerCircular
        size="100"
        thickness="150"
        color="#fcbc19"
        className="diy-loader"
      />
    </div>
  );
}

export default Loader;