import axios from "axios";
import { getAccessToken } from "../utils/jwt";
import { checkAccessToken } from "./interceptor";

const shippingClient = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_ENDPOINT}/CustomerAddress`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${getAccessToken()}`,
  },
});

shippingClient.interceptors.request.use(checkAccessToken);

export const getShipping = async ({ shippingID }) => {
  if (!shippingID) return;

  const { data } = await shippingClient.get(`/${shippingID}`);

  return {
    data,
  };
};

export const addShipping = async ({ accountDetails }) => {
  const { data } = await shippingClient.put("/", accountDetails);
  const { customerAddressID: shippingID } = data;

  return { shippingID };
};

export const patchAllShipping = async ({ shippingID, newData }) => {
  const { editData } = await shippingClient.patch(`/${shippingID}`, newData);

  return { editData };
};

export const getUserShipping = async ({ userID }) => {
  const { data } = await shippingClient.get(`/Users/${userID}`);
  const { Status, Shipping } = data;

  return {
    status: Status,
    data,
  };
};
