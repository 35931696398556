import { BrowserRouter, Routes, Route, useLocation, Navigate } from "react-router-dom";
import { useEffect } from "react";
import TagManager from "react-gtm-module";
import { 
  CusNavbar, Footer as CusFooter, SignUpForm as SignUp, 
  VerificationForm as Verify, CreateUsername, CreatePassword, 
  ThankYouRedirect, LoginForm } from "./components";
import { Account, Cart, Category, Checkout, AuthRedirect, 
  OrderCancelled, OrderConfirmation, OrderDetails,
  OrderFailed, ForgotPassword, ForgotPasswordSuccess,
  ResetPassword, ProductByTag, ProductDetail,
  ProductListing, Promotion, Promotions, RefundDetails,
  Wishlist } from "./pages";
import Home from "./pages/Home";
import Default from "./pages/Default";
import { LoginProvider } from "./contexts/AuthContext";
import { ShippingAddressProvider } from "./contexts/ShippingAddressContext";
import { LanguageProvider } from "./contexts/LanguageContext";
import { CookiesProvider } from "./contexts/CookiesContext";
import { SearchBarProvider } from "./contexts/SearchBarContext";
import { CartProvider } from "./contexts/CartContext";
import { OrderStatusProvider } from "./contexts/OrderStatusContext";
import { SortProductProvider } from "./contexts/SortProductContext";
import { SortReviewProvider } from "./contexts/SortReviewContext";
import PrivateRoute from "./PrivateRoute";
import VerifyRoute from "./VerifyRoute";
import NotFound from "./pages/404";
import { SearchResult } from "./pages/SearchResult";
import "./App.css";
import { AppProvider } from "./contexts/AppContext";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const App = () => {
  useEffect(() => {
    TagManager.initialize({ gtmId: "GTM-MMFHWKW" });
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Navigate to="/th" replace />} /> 
          <Route path="*" element={<DefaultContainer />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

const DefaultContainer = () => (
  <SearchBarProvider>
    <AppProvider>
      <LanguageProvider>
        <LoginProvider>
          <ShippingAddressProvider>
            <CartProvider>
              <OrderStatusProvider>
                <SortProductProvider>
                  <SortReviewProvider>
                    <CookiesProvider>
                      <CusNavbar />
                      <Routes>
                        <Route path="/:lang">
                          <Route index element={<Home />} />
                          <Route path="search" element={<SearchResult />} />
                          <Route path="promotions" element={<Promotions />} />
                          <Route path="promotions/:promotion-p.:pid" element={<Promotion />} />
                          <Route path="products" element={<ProductListing />} />
                          <Route path="products/tags/:id" element={<ProductByTag />}/>
                          <Route path="pages/:pid" element={<Default />} />
                          <Route path=":category/:product-i.:id" element={<ProductDetail />} />
                          <Route path=":category-cat.:id" element={<Category />} />

                          <Route element={<AuthRedirect />}>
                            <Route path="user/*">
                              <Route index element={<LoginForm />} />
                              <Route path="signup" element={<SignUp />} />
                              <Route path="forgot-password" element={<ForgotPassword />} />
                              <Route path="forgot-password/success" element={<ForgotPasswordSuccess />} />
                              <Route path="reset-password" element={<ResetPassword />} />
                              <Route element={<VerifyRoute />}>
                                <Route path="verification" element={<Verify />} />
                                <Route path="password" element={<CreatePassword />} />
                                <Route path="username" element={<CreateUsername />} />
                                <Route path="confirmation" element={<ThankYouRedirect />} />
                              </Route>
                              <Route path="*" element={<NotFound />} />
                            </Route>
                          </Route>

                          <Route element={<PrivateRoute />}>
                            <Route path="wishlist" element={<Wishlist />} />
                            <Route path="cart" element={<Cart />} />
                            <Route path="orders/:id" element={<OrderDetails />} />
                            <Route path="refunds/:id" element={<RefundDetails />} />
                            <Route path="checkout" element={<Checkout />} />
                            <Route path="order-confirmation" element={<OrderConfirmation />}/>
                            <Route path="order-failed" element={<OrderFailed />} />
                            <Route path="order-cancelled" element={<OrderCancelled />} />
                            <Route path="account" element={<Account />} />
                            <Route path="*" element={<NotFound />} />
                          </Route>
                          <Route path="*" element={<NotFound />} />
                        </Route>
                      </Routes>
                      <CusFooter />
                    </CookiesProvider>
                  </SortReviewProvider>
                </SortProductProvider>
              </OrderStatusProvider>
            </CartProvider>
          </ShippingAddressProvider>
        </LoginProvider>
      </LanguageProvider>
    </AppProvider>
  </SearchBarProvider>
);

export default App;
