import { useState } from "react";

export const useFileUpload = () => {
  const [files, setFiles] = useState([]);
  const [filePreviews, setFilePreviews] = useState([]);

  const onFileUpload = (event) => {
    if (!event.target.files) return;

    const uploadedFiles = Array.from(event.target.files).filter(
      (file) => !files.map((file) => file.name).includes(file.name)
    );

    setFiles([...files, ...uploadedFiles]);

    const generatePreviews = async () => {
      const previews = await Promise.all(
        uploadedFiles.map(async (file) => {
          const previewUrl = await getFilePreview(file);
          return (
            <div key={file.name} className="screenshot">
              <img src={previewUrl} alt="File Preview" />
            </div>
          );
        })
      );

      setFilePreviews((prevPreviews) => [...prevPreviews, ...previews]);
    };

    generatePreviews();
  };

  const onFileRemove = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);

    setFiles(updatedFiles);
  };

  const getFilePreview = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        resolve(event.target.result);
      };

      reader.readAsDataURL(file);
    });
  };

  const serializeFiles = async (files) => {
    if (files.length <= 0) return;

    const base64Promises = files.map((file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
          if (!reader.result) return;

          const fileName = file.name;
          const fileType = file.type;
          const content = reader.result.toString().split("base64,")[1];

          resolve({ fileName, fileType, content });
        };

        reader.onerror = (error) => {
          reject(error);
        };

        reader.readAsDataURL(file);
      });
    });

    try {
      const base64Strings = await Promise.all(base64Promises);
      return base64Strings;
    } catch (error) {
      console.error("Error converting files to Base64:", error);
    }
  };

  return {
    files,
    filePreviews,
    onFileUpload,
    onFileRemove,
    serializeFiles,
  };
};
