export const parseJwt = (token) => {
  if (!token) return null;

  let base64Url = token.split(".")[1];
  let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const tokenData = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(tokenData);
};

export const getAccessToken = ({ parse = false } = {}) => {
  const rawAccessToken = localStorage.getItem("accessToken");
  const parsedAccessToken = parseJwt(rawAccessToken);

  return parse ? parsedAccessToken : rawAccessToken;
};
