import axios from "axios";

export const getUsefulLinks = async ({ language }) => {
  const languageParam = language ? "en-us" : "th-th";
    const endpoint = `https://admin.mrdiy.co.th/kentico13_admin/rest/content/currentsite/${languageParam}/childrenof/Footer/GroupLink?format=JSON`;
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: "Basic YWRtaW5pc3RyYXRvcjpXZWJMSVRFU3VwcG9ydDMyITE=",
    },
  };

  const { data } = await axios.get(endpoint, config);

  return {
    documents: data.cms_documents[0].cms_document,
  };
};

export const getDefaultPageContent = async ({ language, pid }) => {
    const languageParam = language ? "en-us" : "th-th";
    const endpoint = `https://admin.mrdiy.co.th/kentico13_admin/rest/content/currentsite/${languageParam}/document/Footer/GroupLink/${pid}?format=JSON`;
    const config = {
        headers: {
            Accept: "application/json",
            Authorization: "Basic YWRtaW5pc3RyYXRvcjpXZWJMSVRFU3VwcG9ydDMyITE=",
        },
    };

    const { data } = await axios.get(endpoint, config);

    return {
        documents: data.cms_documents[0].custom_DefaultPage[0],
    };
};