import { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { HiDocumentText } from "react-icons/hi";
import clsx from "clsx";
import { Loader } from "../../components";
import { checkRefundOrder, putRefundOrders } from "../../api/refund";
import { generateInvoice } from "../../api/invoice";
import { LanguageContext } from "../../contexts/LanguageContext";
import { getCityArea } from "../../api/cityArea";
import { getAccessToken } from "../../utils/jwt";
import Swal from "sweetalert2";
import "./OrderDetails.css";

const OrderDetails = () => {
  const navigate = useNavigate();
  const { id: orderID } = useParams();
  const customerDetails = getAccessToken({ parse: true });
  const { language } = useContext(LanguageContext);

  const [isLoading, setIsLoading] = useState(false);
  const [orderDetails, setOrderDetails] = useState();
  const totalSum = orderDetails?.OrderItems?.reduce(
    (total, item) => total + item.TotalPrice,
    0
  );
  const deliveryFee = orderDetails?.OrderTotalPrice - totalSum;
  const [hasRefund, setHasRefund] = useState(false);
  const [refundOrderID, setRefundOrderID] = useState(0);
  // const [showTaxInvoiceModal, setShowTaxInvoiceModal] = useState(false);
  const [citiesList, setCitiesList] = useState(null);

  useEffect(() => {
    const fetchRefundOrder = async () => {
      try {
        const { refundOrderID } = await checkRefundOrder(atob(orderID));

        setRefundOrderID(refundOrderID || 0);
        setHasRefund(!!refundOrderID);
      } catch (err) {
        console.error(err);
      }
      const { data } = await getCityArea();
      setCitiesList(data);
    };

    const fetchOrderDetails = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_ENDPOINT}/order/${atob(orderID)}`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );
        const { Order: order } = await response.json();
        setOrderDetails(order);
      } catch (err) {
        console.error(err);
      }
    };

    setIsLoading(true);
    Promise.all([fetchRefundOrder(), fetchOrderDetails()]).finally(() => {
      setIsLoading(false);
    });
  }, [orderID, language]);

  const handleRefund = async () => {
    if (hasRefund) {
      navigate(`/${language ? "en" : "th"}/refunds/${btoa(refundOrderID)}`);
      return;
    }

    try {
      setIsLoading(true);
      const { refundOrderID } = await putRefundOrders({
        orderID: atob(orderID),
        orderItems: orderDetails?.OrderItems?.map((item) => ({
          orderItemID: item.OrderItemID,
          refundItemQuantity: 0,
          refundItemCondition: 1,
        })),
        refundDescription: "",
        refundReason: 0,
        refundResolution: 0,
        refundStatus: 1,
        rmaManager: 0,
      });

      if (refundOrderID) {
        navigate(`/${language ? "en" : "th"}/refunds/${btoa(refundOrderID)}`);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownloadInvoice = async () => {
    try {
      const { orderInvoice } = await generateInvoice({
        orderID: atob(orderID),
        language: language ? "en" : "th",
      });

      if (!orderInvoice) return;

      const a = window.open("");
      a.document.write(orderInvoice);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container>
      <Row className="mb-2 mb-md-4">
        <Col>
          <div>
            {isLoading && <Loader status={isLoading} />}

            <h1 className="page-title">
              {language ? "Order Details" : "รายการสั่งซื้อ"}
            </h1>

            {!isLoading && orderDetails ? (
              <div className="card">
                <div key={orderDetails.OrderID} className="card-container">
                  <div className="top">
                    <p className="credit-note">
                      <span>{language ? "Credit Note" : "ใบลดหนี้"}:</span>{" "}
                      {language ? "Sent on" : "ส่งเมื่อ"}{" "}
                      {orderDetails.OrderCompletionDate ?? "-"}
                    </p>
                    <div className="order-status">
                      {/* <p className="description">Parcel is {orderDetails.OrderStatus.toLowerCase()}</p> */}
                      <p
                        className={clsx(
                          "name",
                          orderDetails.OrderStatus.toLowerCase() === "completed"
                            ? "completed-status"
                            : "",
                          orderDetails.OrderStatus.toLowerCase() === "pending"
                            ? "pending-status"
                            : "processing-status"
                        )}
                      >
                        {orderDetails.OrderStatus}
                      </p>
                    </div>
                  </div>

                  <div className="bottom">
                    <div className="order-items">
                      {orderDetails.OrderItems.map(
                        (
                          { Image, OrderItemSKUName, TotalPrice, UnitCounts },
                          index
                        ) => (
                          <div key={index} className="order-item">
                            <img className="item-image" src={Image} alt="" />

                            <div className="item-details">
                              <p className="item-name">
                                {OrderItemSKUName[0].EN}
                              </p>
                              <p className="item-quantity">
                                {language ? "Quantity" : "จำนวน"}: {UnitCounts}
                              </p>
                            </div>

                            <p className="item-total-price">
                              {language
                                ? `THB ${TotalPrice}`
                                : `${TotalPrice} บาท`}
                            </p>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>

                <div className="order-details">
                  <div className="delivery-address">
                    <h4>
                      {language ? "Delivery Address" : "ที่อยู่ในการจัดส่ง"}:
                    </h4>
                    <p>
                      {orderDetails.AddressName} <br />
                      {`${orderDetails.AddressLine_1}, ${orderDetails.AddressLine_2}, ${orderDetails.AddressZip} ${orderDetails.AddressCity}`}
                    </p>
                  </div>

                  <div className="cost-details">
                    <div className="total-sum">
                      <h4>{language ? "Total Sum" : "รวมค่าสินค้า"}</h4>
                      <p>{language ? `THB ${totalSum}` : `${totalSum} บาท`}</p>
                    </div>
                    <div className="delivery-charges">
                      <h4>
                        {language ? "Delivery Charges" : "ค่าบริการจัดส่ง"}
                      </h4>
                      <p>
                        {language ? `THB ${deliveryFee}` : `${deliveryFee} บาท`}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="summary">
                  <div className="total-payment">
                    <h4>
                      {language ? "Total Payment" : "รวมจำนวนเงินทั้งสิ้น"}
                    </h4>
                    <p>
                      {language
                        ? `THB ${orderDetails.OrderTotalPrice}`
                        : `${orderDetails.OrderTotalPrice} บาท`}
                    </p>
                  </div>

                  <div className="button-container">
                    {/* <button onClick={handleRefund} className="refund-button">
                      <span>
                        {language
                          ? "Request for Refund / Return"
                          : "ขอคืนเงิน / คืนสินค้า"}
                      </span>
                    </button> */}
                    <button
                      onClick={handleDownloadInvoice}
                      className="download-invoice-button"
                    >
                      <HiDocumentText />
                      <span>
                        {language
                          ? "Download e-Invoice"
                          : "ดาวน์โหลดใบแจ้งหนี้อิเล็กทรอนิกส์"}
                      </span>
                    </button>
                  </div>
                  {/* Add Shipping Modal */}
                </div>
              </div>
            ) : (
              <div className="d-flex data_wrap row">
                <span className="errormsg text-center txt-30">
                  {language ? "No Order Found" : "ไม่พบคำสั่งซื้อ"}
                </span>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default OrderDetails;
