import "./ImageSkeleton.css";

const ImageSkeleton = () => {
  return (
    <div className="banner-skeleton">
      <div className="pulse"></div>
    </div>
  );
};

export default ImageSkeleton;
