import axios from "axios";
import { getAccessToken } from "../utils/jwt";
import { checkAccessToken } from "./interceptor";

export const refundClient = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_ENDPOINT}/refund`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${getAccessToken()}`,
  },
});

refundClient.interceptors.request.use(checkAccessToken);

export const rmaClient = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_ENDPOINT}/rma`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${getAccessToken()}`,
  },
});

rmaClient.interceptors.request.use(checkAccessToken);

// Refund Orders
export const getRefundOrders = async () => {
  const { data } = await refundClient.get(`/orders`);

  return {
    status: data.status,
    result: data,
  };
};

export const getRefundOrdersById = async (refundOrderID) => {
  const { data } = await refundClient.get(`/orders/${refundOrderID}`);

  return data.data;
};

export const putRefundOrders = async ({
  refundDescription,
  refundReason,
  refundStatus,
  refundResolution,
  rmaManager,
  orderID,
  orderItems,
}) => {
  const { data } = await refundClient.put(`/orders`, {
    refundDescription: refundDescription,
    refundReason: refundReason,
    refundStatus: refundStatus,
    refundResolution: refundResolution,
    rmaManager: rmaManager,
    orderID: orderID,
    orderItems: orderItems,
  });

  return data;
};

export const addRefundOrdersMessages = async ({
  refundOrderID,
  type,
  message,
}) => {
  const { data } = await refundClient.post(
    `/orders/${refundOrderID}/messages`,
    {
      type: type,
      message: message,
    }
  );

  return {
    status: data.status,
    result: data,
  };
};

export const addRefundOrdersScreenShots = async ({
  refundOrderID,
  screenshot,
}) => {
  const { data } = await refundClient.post(
    `/orders/${refundOrderID}/screenshots`,
    {
      screenshot: screenshot,
    }
  );

  return data;
};

// Refund Orders Bank Details
export const getRefundOrdersBankDetails = async (refundOrderID) => {
  const { data } = await refundClient.get(
    `/orders/${refundOrderID}/bank-details`
  );

  return data.data;
};

export const putRefundOrdersBankDetails = async (ID, array) => {
  const { data } = await refundClient.put(`/orders/${ID}/bank-details`, array);

  return {
    statusPutBankDetails: data.status,
    resultPutBankDetails: data,
  };
};

export const getRefundReasonById = async (ID) => {
  const { data } = await refundClient.get(`/reasons/${ID}`);

  return {
    status: data.status,
    result: data,
  };
};

export const postRefundReason = async ({ reasonName, isReturn, isRefund }) => {
  const { data } = await refundClient.post(`/reasons`, {
    reasonName: reasonName,
    isReturn: isReturn,
    isRefund: isRefund,
  });

  return {
    status: data.status,
    result: data,
  };
};

export const updateRefundReasonById = async (
  ID,
  reasonName,
  isRefund,
  isReturn
) => {
  const { data } = await refundClient.patch(`/reasons/${ID}`, {
    reasonName: reasonName,
    isReturn: isReturn,
    isRefund: isRefund,
  });

  return {
    status: data.status,
    result: data,
  };
};

// Refund Resolutions
export const getRefundResolutions = async () => {
  const { data } = await refundClient.get(`/resolutions`);

  return data.data;
};

export const getRefundResolutionById = async (ID) => {
  const { data } = await refundClient.get(`/resolutions/${ID}`);

  return {
    status: data.status,
    result: data,
  };
};

export const postRefundResolution = async ({ resolutionName, ifBank }) => {
  const { data } = await refundClient.post(`/resolutions`, {
    resolutionName: resolutionName,
    ifBank: ifBank,
  });

  return {
    status: data.status,
    result: data,
  };
};

export const updateRefundResolutionById = async (
  ID,
  resolutionName,
  ifBank
) => {
  const { data } = await refundClient.patch(`/resolutions/${ID}`, {
    resolutionName: resolutionName,
    ifBank: ifBank,
  });

  return {
    status: data.status,
    result: data,
  };
};

export const getRefundOrderDetails = async (orderID) => {
  const { data } = await refundClient.get(`/orders/${orderID}`);

  return data;
};

// Refund Status
export const getRefundStatus = async () => {
  const { data } = await refundClient.get(`/status`);

  return data.data;
};

export const getRefundStatusById = async (statusID) => {
  const { data } = await refundClient.get(`/status/${statusID}`);

  return {
    status: data.status,
    result: data,
  };
};

export const postRefundStatus = async ({
  statusName,
  isCompleted,
  ifAnchanto,
}) => {
  const { data } = await refundClient.post(`/status`, {
    statusName: statusName,
    isCompleted: isCompleted,
    ifAnchanto: ifAnchanto,
  });

  return {
    status: data.status,
    result: data,
  };
};

export const updateRefundStatusById = async (
  ID,
  statusName,
  isCompleted,
  ifAnchanto
) => {
  const { data } = await refundClient.patch(`/status/${ID}`, {
    statusName: statusName,
    isCompleted: isCompleted,
    ifAnchanto: ifAnchanto,
  });

  return {
    status: data.status,
    result: data,
  };
};

// RMA
export const getRmaCmsUsers = async () => {
  const { data } = await rmaClient.get(`/cmsusers`);

  return {
    status: data.status,
    result: data,
  };
};

export const getRefundReasons = async () => {
  const { data } = await refundClient.get("/reasons");

  return data.data;
};

export const getRefundConditions = async () => {
  const { data } = await refundClient.get("/conditions");

  return data.data;
};

export const getRefundOrderBankDetails = async (orderID) => {
  const { data } = await refundClient.get(`/orders/${orderID}/bank-details`);

  return data;
};

export const getRefundManagers = async () => {
  const { data } = await refundClient.get(
    `${process.env.REACT_APP_BASE_ENDPOINT}/rma`
  );

  return data;
};

export const addRefundOrderMessage = async (orderID, newMessage) => {
  const { data } = await refundClient.post(`/orders/${orderID}/messages`, {
    message: newMessage,
  });

  return data;
};

export const updateRefundOrder = async (payload) => {
  const { data } = await refundClient.put("/orders", payload);

  return data;
};

export const updateRefundOrderBankDetails = async (id, payload) => {
  const { data } = await refundClient.put(
    `/orders/${id}/bank-details`,
    payload
  );

  return data;
};

export const checkRefundOrder = async (orderID) => {
  const { data } = await refundClient.get(`/orders/${orderID}/check`);

  return data;
};
