import { useContext } from "react";
import { Link } from "react-router-dom";
import { LanguageContext } from "../../contexts/LanguageContext";
import { convertToURLParams } from "../../utils/string";

const Breadcrumb = ({ product }) => {
  const { language } = useContext(LanguageContext);

  return (
    <div className="col">
      <div className="breadcrumb-wrap">
        <ul>
          <li>
            <Link to="/">
              <img alt="home" className="" src="/assets/ico_homebtn.png" />
            </Link>
          </li>
          <li>
            <Link to={`/${language ? "en" : "th"}/${convertToURLParams(language ? product.categoryName : product.categoryNameTH)}-cat.${btoa(product.categoryID)}`}>
              {language ? product.categoryName : product.categoryNameTH}
            </Link>
          </li>
          <li className="current">
            <a>{product?.name?.[language ? "EN" : "TH"] ?? "-"}</a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Breadcrumb;
