import { useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { nanoid } from "nanoid";
import { Link, NavLink } from "react-router-dom";
import { Container, Row, Col, Form } from "react-bootstrap";
import { BsFilterLeft, BsSearch } from "react-icons/bs";
import LazyLoad from "react-lazy-load";
import { Loader } from "../";
import { LanguageContext } from "../../contexts/LanguageContext";
import { SortProductContext } from "../../contexts/SortProductContext";
import { SearchBarContext } from "../../contexts/SearchBarContext";
import { getProductsHome } from "../../api/producthome";
import { getProductsFilter } from "../../api/productfilter";
import { PaginationGroup } from "../";
import { DISPLAY_ITEMS_PER_PAGE as TOTAL_ITEMS_DISPLAY } from "../../constants/config";
import "./DataSec.css";
import ProductCard from "./ProductCard";

/**
 * Data section component to render the data of that category
 * @param {Object} props - The props for the Data Section component
 * @param {string} props.tabTarget - The selected tab
 * @param {string} props.category - The selected category
 * @returns {JSX.Element} - The Data Section component
 */
const DataSec = ({ tabTarget, category }) => {
  const location = useLocation();
  const pageLocated = location.pathname;
  const { cid: categoryID } = useParams();
  const { result } = useContext(SearchBarContext);
  const { language } = useContext(LanguageContext);
  const { point, handlesort } = useContext(SortProductContext);
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [filterTarget, setFilterTarget] = useState(
    pageLocated === `/tab/${categoryID}` ? categoryID : ""
  );
  const [searchName, setSearchName] = useState("");
  const [pageNum, setPageNum] = useState(1);

  const handleFilter = (e) => {
    setFilterTarget(e);
  };

  const tabName =
    categoryID !== undefined ? categoryID.split("-").join(" ") : tabTarget;

  const filteredTag =
    tabName === filterTarget ? filterTarget : `${tabName},${filterTarget}`;

  const filteredEmptyTag =
    filterTarget === "" || filterTarget === "Product Name"
      ? tabName
      : filteredTag;

  useEffect(() => {
    const fetchData = async () => {
      //for homepage
      if (
        (pageLocated === "/home" ||
          pageLocated === "/Home" ||
          pageLocated === "/") &&
        tabTarget !== undefined
      ) {
        setLoading(true);
        setProducts([]);
        const { status, data, length } = await getProductsHome({
          itemsPerPage: 6,
          page: 1,
          tag: tabTarget ? tabTarget : null,
        });

        if (status !== 200) return;
        setProducts(data);
        setLoading(false);
      } else if (pageLocated === "/search") {
        setProducts(result);
        setLoading(false);
      } else {
        const [sortKey, sortDirection] = point.split("_");

        const { status, data, length } = await getProductsFilter({
          page: pageNum,
          pageSize: 20,
          category: category.categoryID,
          tag: pageLocated === `/tab/${categoryID}` ? filteredTag : filterTarget,
          nameFilter: searchName ? searchName : null,
          sortKey: sortKey,
          sortDirection: sortDirection,
        });

        if (status !== 200) return;
        setProducts(data);
        setLoading(false);
      }
    };

    fetchData();
  }, [pageLocated, point, filterTarget, tabTarget, pageNum, result, category]);

  // Search product name
  const submitSearch = async () => {
    // Filter product name when select [product name]
    setLoading(true);
    setProducts([]);
    const { status, data, length } = await getProductsFilter({
      itemsPerPage: 20,
      page: 1,
      category: pageLocated === `/tab/${categoryID}` ? null : filteredEmptyTag,
      tag: pageLocated === `/tab/${categoryID}` ? tabName : null,
      nameFilter: searchName ? searchName : null,
      priceSort: point !== "Product Name" && point ? point : null,
      nameSort: point === "Product Name" ? "ASC" : null,
    });
    if (status !== 200) return;
    setProducts(data);
    setLoading(false);
  };

  const setFilter = (e) => {
    handleFilter(e.target.value);
  };

  const filterOption =
    filterTarget !== undefined ? filterTarget.split("-").join(" ") : "";

  return (
    <>
      {loading && <Loader status={loading} />}

      <Container fluid="xxl">
        {pageLocated !== "/" && (
          <Row className="data_wrap pt-5 pb-0">
            <div className="col">
              <div className="breadcrumb-wrap">
                <ul>
                  <li>
                    <Link to="/">
                      <img
                        alt="home"
                        src={process.env.PUBLIC_URL + "/assets/ico_homebtn.png"}
                      />
                    </Link>
                  </li>
                  <li className="current">
                    <a>{category?.categoryName?.[language ? "EN" : "TH"]}</a>
                  </li>
                </ul>
              </div>
            </div>
          </Row>
        )}

        <Row
          className={`d-flex data_wrap justify-content-between ${
            pageLocated === "/" || pageLocated === "/search"
              ? "d-none"
              : "d-block"
          }`}
        >
          <Col xs={12} sm={6} md={7} className="mb-3">
            <div className="d-md-flex align-items-md-center justify-content-md-start gap-2">
              <section className="btn-card-thi d-flex align-items-center">
                <BsFilterLeft />
                <Form.Select
                  aria-label="Default"
                  className="bg-transparent txt-bold outline-0 border-0"
                  value={filterOption}
                  onChange={(e) => setFilter(e)}
                >
                  <option value="">All</option>
                  {/* <option value="New">New</option>
                  <option value="Best Seller">Best Seller</option>
                  <option value="Most Viewed">Most Viewed</option> */}
                  <option value="Product Name">Product Name</option>
                </Form.Select>
              </section>
              <div
                className={`input_search mt-2 mt-md-0 w-100 max-300px ${
                  filterTarget === "Product Name" ? "d-block" : "d-none"
                }`}
              >
                <input
                  className="form-control"
                  type="search"
                  placeholder="Search name"
                  value={searchName}
                  onChange={(e) => setSearchName(e.target.value)}
                />
                <div
                  className="box_search bg-red-1 link_"
                  onClick={submitSearch}
                >
                  <BsSearch className="text-white" size="1.5em" />
                </div>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={6} md={5} className="mb-3">
            <div className="d-flex align-items-center justify-content-sm-end justify-content-center">
              <p className="me-3 mb-0">Sort by</p>

              <div>
                <Form.Select
                  aria-label="Default"
                  className="cus_form_select txt-bold"
                  value={point}
                  onChange={(e) => handlesort(e)}
                >
                  <option value="">Select Sort</option>
                  <option value="name_ASC">Name a to z</option>
                  <option value="name_DESC">Name z to a</option>
                  <option value="price_ASC">Price low to high</option>
                  <option value="price_DESC">Price high to low</option>
                </Form.Select>
              </div>
            </div>
          </Col>
        </Row>

        <Row className="d-flex data_wrap">
          {!products || products.length <= 0 ? (
            <span className="errormsg text-center txt-30">No Result Found</span>
          ) : (
            <>
              {products?.slice(
                TOTAL_ITEMS_DISPLAY * (pageNum-1),
                TOTAL_ITEMS_DISPLAY * pageNum).map((product, idx) => (
                  <ProductCard key={idx} product={product} categoryName={category?.categoryName?.[language ? "EN" : "TH"]}/>
              ))}
            </>
          )}
        </Row>
        <Row className="mx-auto d-table mb-5">
          {pageLocated !== "/" && (
            <Col xs={12}>
              <PaginationGroup 
                pageNumber={pageNum} 
                setPageNumber={setPageNum} 
                totalItems={products.length} 
                totalItemsDisplay={TOTAL_ITEMS_DISPLAY} 
              />
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
}

export default DataSec;
