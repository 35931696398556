function OcpbLogo() {
  return (
    <a
      style={{ marginLeft: "2rem" }}
      href="https://ocpbdirect.ocpb.go.th/detail/63060400176"
      target="_blank"
      rel="noreferrer"
    >
      <img height={54} src="/assets/ocpb-icon.png" alt="" />
    </a>
  );
}

export default OcpbLogo;
