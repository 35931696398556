import { useState, useEffect, useContext, memo } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LazyLoad from "react-lazy-load";
import { Row, Col, Card, Tabs, Tab } from "react-bootstrap"; 
import { nanoid } from "nanoid";
import Parser from "html-react-parser";
import { PaginationGroup, Loader } from "../"
import { getPromotions } from "../../api/promotion";
import { LanguageContext } from "../../contexts/LanguageContext";
import { DISPLAY_ITEMS_PER_PAGE as TOTAL_ITEMS_DISPLAY } from "../../constants/config";
import "./PromotionTabSec.css";
import { convertToURLParams } from "../../utils/string";

const PromotionTabSec = () => {
  const { language } = useContext(LanguageContext);
  const [currentPromoList, setCurrentPromoList] = useState([]);
  const [tabTarget, settabTarget] = useState("current");
  const [loading, setLoading] = useState(false);
  const [pageNum, setPageNum] = useState(1);

  useEffect(() => {
    //GET current promoData - not showing past promoData
    const fetchPromoData = async () => {
      try {
        setCurrentPromoList([]);
        setLoading(true);
        const { status, data, length } = await getPromotions({
          page: pageNum,
          showedStatus: tabTarget === "current" ? "active" : "past",
          itemsPerPage: 20,
          language: language ? "en-US" : "th-TH",
        });

        if (!data.length) return;
        setCurrentPromoList(data);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchPromoData();
  }, [tabTarget, language]);

  const onTabChange = (e) => {
    if (e === "current") {
      settabTarget("current");
    } else {
      settabTarget("past");
    }
  };

  const renderCurrentPromotion = () => {
    return currentPromoList?.slice(
      TOTAL_ITEMS_DISPLAY * (pageNum-1),
      TOTAL_ITEMS_DISPLAY * pageNum).map((item) => (
      <Col
        xs={12}
        md={6}
        lg={4}
        className="d-flex align-items-stretch mb-4"
        key={nanoid()}
      >
        <Card className="Promotion-Card">
          <LazyLoad threshold={0}>
            <Link to={`/${language ? "en" : "th"}/promotions/${convertToURLParams(item?.promotionName)}-p.${btoa(item?.promotionID)}?${tabTarget}`}>
              <Card.Img variant="top" src={item?.listingImage} />
            </Link>
          </LazyLoad>
          <Card.Body className="justify-content-between d-flex flex-column h-100">
            <Card.Title className="mb-4">
              <small className="d-table mb-3 bg-red-1 txt-15 text-white p-1 px-2">
                {item?.startDateTime}
              </small>

              {item.description.length > 60 ? (
                <p className="d-block text-grey-1 txt-20">
                  {Parser(item?.description.substring(0, 60))}...
                </p>
              ) : (
                <p className="d-block text-grey-1 txt-20">
                  {Parser(item?.description)}
                </p>
              )}
            </Card.Title>
            <Link
              to={`/${language ? "en" : "th"}/promotions/${convertToURLParams(item?.promotionName)}-p.${btoa(item?.promotionID)}?${tabTarget}`}
              className={`btn mb-3 btn-login  btn-card-main w-100 d-block`}
            >
              {language ? "More Info" : "ข้อมูลมากกว่านี้"}
            </Link>
          </Card.Body>
        </Card>
      </Col>
    ));
  };

  return (
    <>
      {loading && <Loader status={loading} />}
      <div className="py-5 PromotionTabSec">
        <Tabs
          defaultActiveKey={tabTarget}
          onSelect={(e) => onTabChange(e)}
          id="fill-tab-example"
          variant="tabs"
          className="justify-content-center"
          justify
        >
          <Tab
            eventKey="current"
            title={language ? "Current Promotions" : "โปรโมชั่นปัจจุบัน"}
            className="p-3 p-md-5"
          >
            <Row>{renderCurrentPromotion()}</Row>
            <Row className={`mx-auto d-table mb-5`}>
              <Col xs={12}>
                <PaginationGroup
                  pageNumber={pageNum}
                  setPageNumber={setPageNum}
                  totalItems={currentPromoList?.length}
                  totalItemsDisplay={TOTAL_ITEMS_DISPLAY}
                />
              </Col>
            </Row>
          </Tab>
          <Tab
            eventKey="past"
            title={language ? "Past Promotions" : "โปรโมชั่นที่ผ่านมา"}
            className="p-3 p-md-5"
          >
            <Row>{renderCurrentPromotion()}</Row>
            <Row className={`mx-auto d-table mb-5`}>
              <Col xs={12}>
                <PaginationGroup
                  pageNumber={pageNum}
                  setPageNumber={setPageNum}
                  totalItems={currentPromoList?.length}
                  totalItemsDisplay={TOTAL_ITEMS_DISPLAY}
                />
              </Col>
            </Row>
          </Tab>
        </Tabs>
      </div>
    </>
  );
}

export default memo(PromotionTabSec);
