import axios from 'axios'

const smsClient = axios.create({
    baseURL: 'https://smsservice-mrdiy-linux.azurewebsites.net/password',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
})

export const forgotPassword = async ({ phoneNumber }) => {
    const response = await smsClient.post('/forgot', {
        phoneNumber,
    })

    return response.data
}

export const resetPassword = async ({ token, newPassword }) => {
    const response = await smsClient.post('/reset', {
        token,
        newPassword,
    })

    return response.data
}
