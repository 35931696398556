import axios from "axios";

const emailClient = axios.create({
    baseURL: `${process.env.REACT_APP_BASE_ENDPOINT}/resetpassword`,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    },
});

export const requestPassword = async ({ userName }) => {
    const { status, data } = await emailClient.post("/", {
        userName: userName,
    });

    return {
        status: status,
        data: data,
    };
};

export const verifyToken = async ({ token }) => {
    const { status, data } = await emailClient.get("/", {
        token: token,
    });

    return {
        status: status,
        data: data,
    };
};

export const updatePassword = async ({ token, Password }) => {
    const { status, data } = await emailClient.patch("/", {
        token: token,
        Password: Password
    });

    return {
        status: status,
        data: data,
    };
};
