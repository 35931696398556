import { useContext } from "react";
import { Link } from "react-router-dom";
import { LanguageContext } from "../../contexts/LanguageContext";

function LogoutDropdown({ logout }) {
  const { language } = useContext(LanguageContext);
  let globalIMG = process.env.PUBLIC_URL;

  return (
    <div className="dropdown">
      <div
        className="dropdown-toggle"
        href="#"
        role="button"
        id="drp_logout"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <img
          className="img-fluid img-50 me-2"
          src={globalIMG + "/assets/ico_login.png"}
        />
      </div>

      <ul className="dropdown-menu" aria-labelledby="drp_logout">
        <li className="p-2 border-bottom-1">
          <Link to={`${language ? "en" : "th"}/account`}>{language ? "Account" : "บัญชี"}</Link>
        </li>
        <li className="p-2 border-bottom-1">
          <div onClick={logout}>
            <Link to={`${language ? "en" : "th"}/account`}>{language ? "Log out" : "ออกจากระบบ"}</Link>
          </div>
        </li>
      </ul>
    </div>
  );
}

export default LogoutDropdown;
