import { useState, useEffect, useContext, useRef } from "react";
import { Container, Row, Col, Form, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { VscClose } from "react-icons/vsc";
import { HiChevronLeft } from "react-icons/hi";
import LazyLoad from "react-lazy-load";
import TagManager from "react-gtm-module";
import { nanoid } from "nanoid";
import jwt_decode from "jwt-decode";
import yupPassword from "yup-password";
import { AuthContext } from "../../contexts/AuthContext";
import { LanguageContext } from "../../contexts/LanguageContext";
import { ShippingAddressContext } from "../../contexts/ShippingAddressContext";
import { saveTaxInfo } from "../../api/taxInvoiceInfo";
import {
  addShipping,
  getShipping,
  getUserShipping,
  patchAllShipping,
} from "../../api/shipping.js";
import { getAddress } from "../../api/customerAddress.js";
import { getOrderStatus, updateOrder } from "../../api/order.js";
import { Loader, FormField } from "../../components";
import { useValidation } from "../../hooks/validation.js";
import { getShoppingCartByID } from "../../api/shoppingCart.js";
import { getCountryState } from "../../api/countryState.js";
import { getCityArea } from "../../api/cityArea.js";
import { getAccessToken } from "../../utils/jwt";
import "./Checkout.css";

const Checkout = () => {
  const ref = useRef(null);
  const customerDetails = getAccessToken({ parse: true });
  const { language } = useContext(LanguageContext);
  const { myUserID } = useContext(AuthContext);
  const { createShipList, resetShipList, createAllShipList, allShipList } =
    useContext(ShippingAddressContext);
  const [couponValidStatus, setCouponValidStatus] = useState(0);

  //1. Define form schema
  const yup = require("yup");
  require("yup-password")(yup);
  yupPassword(yup);

  //check if this is international phone
  const schema = yup.object().shape({
    addressFullName: yup.string().required("Required"),
    addressContactNumber: yup.string().required("Required"),
    addressNoBuilding: yup.string().required("Required"),
    addressPostalCode: yup.string().required("Required"),
  });
  const tagManagerArgs = {
    gtmId: "GTM-MMFHWKW",
    events: {
      begin_checkout: "beginCheckout",
    },
  };

  TagManager.initialize(tagManagerArgs);
  //2. Initialize the useValidation hook
  const { fields, errors, useField, validateForm } = useValidation(schema);

  //3. Define form fields

  //for address form
  const { addressFullNameField, setAddressFullNameField } =
    useField("addressFullName");
  const { addressContactNumberField, setAddressContactNumberField } = useField(
    "addressContactNumber"
  );
  let [cityState, setCityState] = useState(null);
  const { addressNoBuildingField, setAddressNoBuildingField } =
    useField("addressNoBuilding");
  const [addressNoBuilding2Field, setAddressNoBuilding2Field] = useState("");
  const { addressCountryField, setAddressCountryField } =
    useField("addressCountry");
  const { addressPostalCodeField, setAddressPostalCodeField } =
    useField("addressPostalCode");
  const [showAddressValidation, setShowAddressValidation] = useState(false);
  let [accountDetails, setAccountDetails] = useState({
    UsersID: parseInt(myUserID),
    AddressName: "",
    AddressPhone: "",
    AddressLine_1: "",
    AddressLine_2: "",
    AddressStateID: "",
    AddressCity: "",
    AddressSuburbs: "",
    Country: "",
    Postcode: "",
  });
  //coupon Codes
  const [couponCode, setCouponCode] = useState("");
  //tax invoice request
  const [taxInvoiceRequest, setTaxInvoiceRequest] = useState("");
  const [showTaxInvoiceModal, setShowTaxInvoiceModal] = useState(false);
  const [citiesList, setCitiesList] = useState(null);
  const [taxInvoiceReqPayload, setTaxInvoiceReqPayload] = useState({});

  //fetch AddressState Api
  const [addressStateLists, setAddressStateLists] = useState([]);
  const [showAddressStateID, setShowAddressStateID] = useState(null);
  const [addressStateID, setAddressStateID] = useState(null);
  const [addressStateName, setAddressStateName] = useState(null);
  //fetch city & suburbas Api
  const [cityAreaList, setCityAreaList] = useState([]);
  //fetch city Api
  const [addressCityLists, setAddressCityLists] = useState([]);
  const [updateAddressCityLists, setUpdateAddressCityLists] = useState([]);
  const [showAddressCity, setShowAddressCity] = useState(null);
  const [addressCity, setAddressCity] = useState(null);
  //fetch suburbs Api
  const [updateAddressPostcodeLists, setUpdateAddressPostcodeLists] = useState(
    []
  );
  const [addressPostcodeLists, setAddressPostcodeLists] = useState([]);
  const [addressSuburbsLists, setAddressSuburbsLists] = useState([]);
  const [updateAddressSuburbsLists, setUpdateAddressSuburbsLists] = useState(
    []
  );

  const [showAddressSuburbs, setShowAddressSuburbs] = useState(null);
  const [addressSuburbs, setAddressSuburbs] = useState(null);
  //error form select
  const [addressStateError, setAddressStateError] = useState("");
  const [addressCityError, setAddressCityError] = useState("");
  const [addressSuburbsError, setAddressSuburbsError] = useState("");
  //--error form select
  const [addressPhoneError, setAddressPhoneError] = useState("");

  //state update shipping -patch
  let [updateAddressName, setUpdateAddressName] = useState(""); //--not required
  let [updateAddressPhone, setUpdateAddressPhone] = useState(""); //--not required

  let [updateAddressLine_1, setUpdateAddressLine_1] = useState("");
  let [updateAddressLine_2, setUpdateAddressLine_2] = useState("");
  let [updateAddressStateID, setUpdateAddressStateID] = useState("");
  let [updateAddressCity, setUpdateAddressCity] = useState("");
  let [updateAddressSuburbs, setUpdateAddressSuburbs] = useState("");
  let [updateAddressStateIDValue, setUpdateAddressStateIDValue] = useState(""); //--e.target.value
  let [updateAddressCityValue, setUpdateAddressCityValue] = useState(""); //--e.target.value
  let [updateAddressSuburbsValue, setUpdateAddressSuburbsValue] = useState(""); //--e.target.value

  let [updateCountry, setUpdateCountry] = useState("");
  let [updatePostcode, setUpdatePostcode] = useState("");
  let [updateDefault, setUpdateDefault] = useState(false);
  //error form select
  const [updateAddressStateError, setUpdateAddressStateError] = useState("");
  const [updateAddressCityError, setUpdateAddressCityError] = useState("");
  const [updateAddressSuburbsError, setUpdateAddressSuburbsError] =
    useState("");

  let [showAddAddressModal, setShowAddAddressModal] = useState(false);
  //default Address
  const defaultTrue = allShipList.find((list) => list.ifDefault === true);
  //state
  //localstorage cartList
  let [cartList, setCartList] = useState([]);
  //display default shipping address
  let [localShipAddress, setLocalShipAddress] = useState(defaultTrue);
  //display default billing address
  let [localBillingAddress, setLocalBillingAddress] = useState(defaultTrue);
  let [resetlocalBillingAddress, setResetLocalBillingAddress] = useState(false);
  //payment
  const [payMethod, setPayMethod] = useState("");
  const [orderID, setOrderID] = useState("");
  const [totalPrice, setTotalPrice] = useState("");
  //open modal add shipping address if data is empty
  let [show, setShow] = useState(false);
  let [showOpenAllShipList, setShowOpenAllShipList] = useState(false);
  let [filterID, setFilterID] = useState("");
  //openBillingShipList
  let [showOpenBillingShipList, setShowOpenBillingShipList] = useState(false);
  let [showChange, setShowChange] = useState(false);
  let [tempShipAddressID, setTempShipAddressID] = useState();
  let [tempBillingShipAddressID, setTempBillingShipAddressID] = useState();
  let [editID, setEditID] = useState();
  let [currentDate, setCurrentDate] = useState();
  const [loading, setLoading] = useState(false);
  const [errormsg, setErrormsg] = useState(false);

  // New Section
  const [oriTotalAmount, setOriTotalAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [couponValue, setCouponValue] = useState(0);
  const [couponErrorMessage, setCouponErrorMessage] = useState("");

  useEffect(() => {
    setAccountDetails({
      ...accountDetails,
      UsersID: parseInt(myUserID),
      AddressName: addressFullNameField,
      AddressPhone: addressContactNumberField,
      AddressLine_1: addressNoBuildingField,
      AddressLine_2: addressNoBuilding2Field,
      AddressStateID: 122,
      AddressCity: addressCity,
      AddressSuburbs: addressSuburbs,
      Country: addressCountryField,
      Postcode: addressPostalCodeField,
    });
  }, [fields, addressCity, addressSuburbs, citiesList]);
  const [shippingCost, setShippingCost] = useState(0);

  //cart data

  //getCountryState
  useEffect(() => {
    const getCountryStateApi = async () => {
      const data = await getCountryState();
      setAddressStateLists(data);
    };
    getCityAreaApi();
    getCountryStateApi();
  }, []);

  const selectAddressStateID = (e) => {
    let filterAddressID = addressStateLists?.filter(
      (list, index) => index === JSON.parse(e.target.value)
    );
    setShowAddressStateID(e.target.value);
    setAddressStateID(filterAddressID[0]?.stateID);
    setAddressStateName(filterAddressID[0]?.stateName);
    setAddressStateError(e.target.value !== "0" ? "" : "required");
  };
  const selectUpdateAddressStateID = (e) => {
    let filterState = addressStateLists?.filter(
      (list, index) => index === JSON.parse(e.target.value)
    );
    setUpdateAddressStateID(filterState[0]?.stateID);
    setUpdateAddressStateIDValue(e.target.value);
    setUpdateAddressStateError(e.target.value !== "0" ? "" : "required");
  };

  const getCityAreaApi = async () => {
    const { data } = await getCityArea();
    setCityState(data);
    let jsonFilter = language ? data?.EN : data?.TH;
    setCityAreaList(jsonFilter.map((list) => list));
    setAddressCityLists(jsonFilter.map((list) => list?.CityName));
    setUpdateAddressCityLists(jsonFilter.map((list) => list?.CityName));
  };
  //getCityAreaState
  useEffect(() => {
    let jsonFilter = language ? cityState?.EN : cityState?.TH;
    setCityAreaList(jsonFilter?.map((list) => list));
    setAddressCityLists(jsonFilter?.map((list) => list?.CityName));
    setUpdateAddressCityLists(jsonFilter?.map((list) => list?.CityName));
  }, [language]);

  const selectCityName = async (e) => {
    //await resetArea();
    let filterCity = updateAddressCityLists?.filter(
      (list, index) => index === JSON.parse(e.target.value)
    );
    await setShowAddressCity(e.target.value);
    await setAddressCity(filterCity[0]);
    await generateAreaList(filterCity[0]);
    await setAddressCityError(e.target.value !== "" ? "" : "required");
  };

  const selectUpdateCityName = async (e) => {
    let filterCity = updateAddressCityLists?.filter(
      (list, index) => index === JSON.parse(e.target.value)
    );

    setUpdateAddressCityValue(e.target.value);
    setUpdateAddressCity(filterCity[0]);
    generateUpdateAreaList(filterCity[0]);
    setUpdateAddressCityError(e.target.value !== "" ? "" : "required");
  };

  //getAreaState
  const generateAreaList = (cityName) => {
    const filterAreaList = cityAreaList?.filter(
      (list) => list.CityName === cityName
    );
    setAddressSuburbsLists(filterAreaList[0]?.AreaName);
  };
  const generateUpdateAreaList = (cityName) => {
    const filterAreaList = cityAreaList?.filter(
      (list) => list.CityName === cityName
    );
    setUpdateAddressSuburbs(filterAreaList[0]?.AreaName[0]);
    setUpdateAddressSuburbsLists(filterAreaList[0]?.AreaName);
  };

  const selectSuburbsID = (e) => {
    let filterSuburb = addressSuburbsLists?.filter(
      (list, index) => index === JSON.parse(e.target.value)
    );
    setShowAddressSuburbs(e.target.value);
    setAddressSuburbs(filterSuburb[0].AreaName);
    setAddressSuburbsError(e.target.value !== "" ? "" : "required");
    setAddressPostcodeLists(filterSuburb[0].Postcode);
  };
  const selectUpdateSuburbsID = (e) => {
    let filterSuburb = updateAddressSuburbsLists?.filter(
      (list, index) => index === JSON.parse(e.target.value)
    );

    setUpdateAddressSuburbsValue(e.target.value);
    setAddressSuburbs(filterSuburb[0].AreaName);
    setUpdateAddressSuburbs(filterSuburb[0].AreaName);
    setUpdateAddressSuburbsError(e.target.value !== "" ? "" : "required");
    setUpdateAddressPostcodeLists(filterSuburb[0].Postcode);
  };

  const handleSubmitCoupon = async () => {
    const couponCodeValid = await getShoppingCart();
    setCouponValidStatus(couponCodeValid);
  };

  const resetArea = async () => {
    await setAddressSuburbsLists([]);
    await setShowAddressSuburbs(null);
    await setAddressSuburbs(null);
  };

  const getShoppingCart = async () => {
    setLoading(true);
    let finalCartList = JSON.parse(localStorage.getItem("cartToCheckOut"));
    let couponCodes = couponCode === "undefined" ? "" : couponCode;

    var shippingAddressID =
      localShipAddress?.AddressID === "undefined"
        ? 0
        : localShipAddress?.AddressID;
    try {
      const { shoppingCart, status } = await getShoppingCartByID({
        userID: customerDetails?.customerID,
        shippingAddressID,
        couponCodes,
      });
      setShippingCost(shoppingCart.shippingCost);

      if (finalCartList) {
        setCartList(finalCartList);
      } else {
        setCartList(shoppingCart?.products);
      }

      setTotalAmount(
        parseFloat(shoppingCart.totalDiscountedPrice) +
          parseFloat(shoppingCart.shippingCost)
      );
      setOriTotalAmount(
        parseFloat(shoppingCart.totalDiscountedPrice) +
          parseFloat(shoppingCart.shippingCost)
      );
      setCouponValue(
        shoppingCart.giftCardValue > 0
          ? shoppingCart.giftCardValue
          : shoppingCart.discountCodeValue
      );
      setCouponErrorMessage(shoppingCart.giftCardErrMsg);

      return shoppingCart.ifCodeValid;
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const getShoppingCartWithID = async (shippingAddressID) => {
    setLoading(true);
    let finalCartList = JSON.parse(localStorage.getItem("cartToCheckOut"));
    var couponCodes = couponCode === "undefined" ? "" : couponCode;
    try {
      const { shoppingCart, status } = await getShoppingCartByID({
        userID: customerDetails?.customerID,
        shippingAddressID,
        couponCodes,
      });
      setShippingCost(shoppingCart.shippingCost);
      if (finalCartList) {
        setCartList(finalCartList);
      } else {
        setCartList(shoppingCart?.products);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  //display cartlist
  useEffect(() => {
    getShoppingCart();
  }, []);

  //click to open modal add shipping address if data is really empty
  const handleClose = () => {
    setShow(false);
    setShowOpenAllShipList(true);
  };
  const handleShow = () => {
    setAccountDetails({
      UsersID: parseInt(myUserID),
      AddressName: "",
      AddressPhone: "",
      AddressLine_1: "",
      AddressLine_2: "",
      AddressStateID: "",
      AddressCity: "",
      AddressSuburbs: "",
      Country: "",
      Postcode: "",
    });
    resetShipList();
    setShow(true);
    setShowOpenAllShipList(false);
  };

  //add shipping json -- submit
  const submitForm = async (e) => {
    e.preventDefault();
    const isFormValid = validateForm();

    if (isFormValid) {
      //PUT ADDED SIGNLE DATA
      const { shippingID } = await addShipping({ accountDetails });
      // get shipping api
      const data = await getShipping({
        shippingID,
      });

      if (shippingID === undefined) {
        setErrormsg(true);
      } else {
        setErrormsg(false);
        if (data.Status === 200) {
          createShipList(data.customerAddressID);
          setCurrentDate(new Date());
        } else {
          setShowAddAddressModal(true);
        }
        setCurrentDate(new Date());
        setShowAddAddressModal(false);
      }

      setShow(false);
      setShowOpenAllShipList(false);
    }
  };

  //radio set default address
  const selectAddress = (e) => {
    if (e !== true) return;
    setUpdateDefault(e);
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  // after click the change - open the modal, select the listID and click edit
  const handleShowChange = async (id) => {
    setFilterID(id);
    let filterlist = allShipList.find((list) => list?.AddressID === id);

    //find result from stateID , city, suburbs == find e.target.value
    const currentAddressStateValue = addressStateLists?.findIndex(
      (list, index) => list?.stateID === filterlist?.AddressState
    );
    const currentAddressCityValue = addressCityLists?.findIndex(
      (list, index) => list === filterlist?.AddressCity
    );
    //check current arealist method
    let filterCity = addressCityLists?.filter(
      (list, index) => list === filterlist?.AddressCity
    );
    const thisCityIsTriggered = cityAreaList?.filter(
      (list) => list?.CityName === filterCity[0]
    );
    const currentAreaIsTriggeredValue =
      thisCityIsTriggered[0]?.AreaName?.findIndex(
        (list) => list === filterlist?.AddressSuburbs
      );

    setUpdateAddressName(filterlist?.AddressName);
    setUpdateAddressPhone(filterlist?.AddressPhone);
    setUpdateAddressLine_1(filterlist?.AddressLine1);
    setUpdateAddressLine_2(filterlist?.AddressLine2);
    setUpdateAddressStateID(filterlist?.AddressState);
    setUpdateAddressCity(filterlist?.AddressCity);
    setUpdateAddressSuburbs(filterlist?.AddressSuburbs);
    setUpdateCountry(filterlist?.Country);
    setUpdatePostcode(filterlist?.Postcode);
    setUpdateDefault(filterlist?.ifDefault);
    //set e.target.value
    setUpdateAddressStateIDValue(currentAddressStateValue);
    setUpdateAddressCityValue(currentAddressCityValue);
    setUpdateAddressSuburbsValue(currentAreaIsTriggeredValue);
    //setAddressSuburbsLists
    setUpdateAddressSuburbsLists(thisCityIsTriggered[0]?.AreaName);
    setCurrentDate(new Date());
    setShowChange(true);
  };

  const handleTaxInvoiceReq = async (check) => {
    setTaxInvoiceRequest(check);
    const { data } = await getCityArea();
    setCitiesList(data);
    if (check) {
      setShowTaxInvoiceModal(true);
    }
  };

  const submitTaxInvoiceReqForm = async (payload) => {
    //save info
    setTaxInvoiceReqPayload(payload);
    setShowTaxInvoiceModal(false);
  };

  const handleSubmitChange = async (e) => {
    let filterlist = allShipList.find((list) => list.AddressID === filterID);

    const newData = {
      UsersID: parseInt(myUserID),
      AddressName: updateAddressName,
      AddressPhone: updateAddressPhone,
      AddressLine_1: updateAddressLine_1,
      AddressLine_2: updateAddressLine_2,
      AddressStateID: parseInt(122),
      AddressCity: updateAddressCity,
      AddressSuburbs: updateAddressSuburbs,
      Country: updateCountry,
      Postcode: updatePostcode,
      ifDefault: updateDefault,
    };

    const { editData } = await patchAllShipping({
      shippingID: filterlist.AddressID,
      newData,
    });

    setEditID(filterlist.AddressID);

    const { status, data } = await getUserShipping({ userID: myUserID });
    const dataSelected = data.Shipping.filter(
      (item) => item.AddressID === filterlist.AddressID
    );

    if (tempShipAddressID === tempBillingShipAddressID) {
      setLocalBillingAddress(dataSelected[0]);
    } else {
      setLocalShipAddress(dataSelected[0]);
    }

    setCurrentDate(new Date());
    if (data === undefined) return;
    setShowChange(false);
  };

  const submitChangeHandler = async (e) => {
    e.preventDefault();

    let filterlist = allShipList.find((list) => list.AddressID === filterID);

    const newData = {
      UsersID: parseInt(myUserID),
      AddressName: updateAddressName,
      AddressPhone: updateAddressPhone,
      AddressLine_1: updateAddressLine_1,
      AddressLine_2: updateAddressLine_2,
      AddressStateID: parseInt(122),
      AddressCity: updateAddressCity,
      AddressSuburbs: updateAddressSuburbs,
      Country: updateCountry,
      Postcode: updatePostcode,
      ifDefault: updateDefault,
    };
    const { editData } = await patchAllShipping({
      shippingID: filterlist.AddressID,
      newData,
    });
    setEditID(filterlist.AddressID);
    setCurrentDate(new Date());

    setShowChange(false);
  };

  //openAllShipList
  const openAllShipList = () => {
    setShowOpenAllShipList(true);
  };

  //after openAllShipList -- radio select the listID
  const createTempShipAddress = (e) => {
    setTempShipAddressID(e);
    setCurrentDate(new Date());
  };
  //displaynewshippingadd
  const submitTempShipAddress = async (e) => {
    e.preventDefault();

    const { status } = await getAddress({
      addressID: tempBillingShipAddressID,
    });

    if (status === undefined || status === 500) return;

    const { data } = await getUserShipping({ userID: myUserID });
    const dataSelected = data.Shipping.filter(
      (item) => item.AddressID === tempShipAddressID
    );

    setLocalShipAddress(dataSelected[0]);
    setShowOpenAllShipList(false);
    setCurrentDate(new Date());
    getShoppingCartWithID(dataSelected[0].AddressID);
  };

  //openAllShipList
  const openBillingShipList = () => {
    setShowOpenBillingShipList(true);
  };
  //after openAllShipList -- radio select the listID
  const createTempBillingShipAddress = (e) => {
    setTempBillingShipAddressID(e);
    setCurrentDate(new Date());
  };
  //displaynewBillingAddadd
  const submitTempBillingShipAddress = async (e) => {
    e.preventDefault();

    const { status } = await getAddress({
      addressID: tempBillingShipAddressID,
    });

    if (status === undefined || status === 500) return;

    const { data } = await getUserShipping({ userID: myUserID });
    const dataSelected = data.Shipping.filter(
      (item) => item.AddressID === tempBillingShipAddressID
    );
    setLocalBillingAddress(dataSelected[0]);
    setShowOpenBillingShipList(false);
    setCurrentDate(new Date());
  };

  //main localsort data
  useEffect(() => {
    const fetchUserShipping = async () => {
      const { status, data } = await getUserShipping({ userID: myUserID });
      if (status !== "200") return;
      createAllShipList(data.Shipping);
    };
    fetchUserShipping();
  }, [currentDate]);

  //total sum price
  let subTotalPrice = cartList.reduce(
    (total, item) =>
      total +
      (item?.VolumeDiscountValue > 0
        ? item?.originalPrice *
          (1 - (item?.VolumeDiscountValue || 0)) *
          item?.quantity
        : item?.discountedPrice * item?.quantity),
    0
  );

  let discountFee = (subTotalPrice * 7) / 100;
  let actualFee = subTotalPrice - discountFee;
  let shippingFee = shippingCost;

  const submitCheck = async () => {
    try {
      setLoading(true);
      const customerID = localStorage.getItem("accessToken");
      let decoded = jwt_decode(customerID);

      const controller = new AbortController();

      const finalShipAddressID =
        tempShipAddressID === undefined
          ? defaultTrue?.AddressID
          : tempShipAddressID;

      const finalBillingShipAddressID =
        tempBillingShipAddressID === undefined
          ? defaultTrue?.AddressID
          : tempBillingShipAddressID;

      const { shoppingCart } = await getShoppingCartByID({
        userID: customerDetails?.customerID,
        couponCodes: couponCode,
      });

      if (
        finalBillingShipAddressID === undefined ||
        finalShipAddressID === undefined
      ) {
        setShowAddressValidation(true);
        setLoading(true);
      } else {
        setShowAddressValidation(false);
        let data = {
          ShoppingCartID: shoppingCart.id,
          CustomerID: decoded.customerID,
          ShippingID: finalShipAddressID,
          BillingID: finalBillingShipAddressID,
          CouponCode: couponCode,
          CouponValue: couponValue,
        };

        const { status, orderID } = await updateOrder(data);
        if (status != "903" && orderID) {
          if (taxInvoiceRequest) {
            setTaxInvoiceReqPayload((obj) => {
              saveTaxInfo({ ...obj, orderId: orderID });
              return { ...obj, orderId: orderID };
            });
          }
          const { status, order } = await getOrderStatus({
            orderID,
          });

          if (status === "200") {
            const dataLayer = {
              event: "purchase",
              ecommerce: {
                transaction_id: "",
                value: totalAmount,
                tax: discountFee,
                shipping: shippingFee,
                currency: "THB",
                coupon: couponValue !== 0 ? couponCode : "",
                items: cartList,
              },
            };
            TagManager.dataLayer({ dataLayer });
            setOrderID(order["OrderID"]);
            setTotalPrice(parseFloat(order["OrderTotalPrice"]));
            setTimeout(() => {
              localStorage.removeItem("cartNum");
              localStorage.removeItem("cartID");
              document.forms["payFormCcard"].submit();
            }, 2000);
          }
        } else {
          alert(
            "Please make sure the Address Selected has correct Suburbs, Area, Postcode and Phone Number"
          );
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  let globalIMG = process.env.PUBLIC_URL;
  return (
    <div className="cart_page px-2 px-md-3 px-lg-5">
      {loading && <Loader status={loading} />}
      {/* add shipping modal */}
      <Modal
        show={show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          {/* add single shipping list */}
          {/* createShipList */}
          <div className="ProductDetail_Panel_Box" onClick={handleClose}>
            <VscClose className="vs_close" />
          </div>
          {/* submit single shipping list */}
          <form className="container-lg data_wrap">
            <Row className="mb-3 text-center">
              <Col>
                <h2 className="mb-4 title-center">
                  {language ? "Add Address" : "เพิ่มที่อยู่สำหรับจัดส่งสินค้า"}
                </h2>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col sm={12} className="px-4 data_wrap">
                <Row className="mb-4">
                  <Col sm={12} md={6}>
                    <FormField
                      value={addressFullNameField}
                      setValue={setAddressFullNameField}
                      error={errors.addressFullName}
                      labelText=""
                      placeholder={language ? "Full Name*" : "ชื่อ-นามสกุล*"}
                      fieldType="text"
                      inputType="Full Name*"
                    />
                  </Col>
                  <Col sm={12} md={6}>
                    <FormField
                      value={addressContactNumberField}
                      setValue={setAddressContactNumberField}
                      error={errors.addressContactNumber}
                      labelText=""
                      placeholder={
                        language ? "Contact Number*" : "เบอร์โทรติดต่อ*"
                      }
                      fieldType="number"
                      inputType="Contact Number*"
                    />
                    <span
                      className={`errormsg ${
                        addressPhoneError === true ? "d-block" : "d-none"
                      }`}
                    >
                      Please make sure your phone number is valid
                    </span>
                  </Col>
                  <Col sm={12}>
                    <FormField
                      value={addressNoBuildingField}
                      setValue={setAddressNoBuildingField}
                      error={errors.addressNoBuilding}
                      labelText=""
                      placeholder={
                        language
                          ? "Input address in EN*"
                          : "ใส่ที่อยู่เป็นภาษาไทย*"
                      }
                      fieldType="text"
                      inputType="Input address in EN"
                    />
                  </Col>
                  <Col sm={12}>
                    <div>
                      <div className="input-relative mb-3">
                        <input
                          type={"text"}
                          placeholder={
                            language
                              ? "Input address in EN -- optional"
                              : "ใส่ที่อยู่เป็นภาษาไทย"
                          }
                          value={addressNoBuilding2Field}
                          className="block form-control"
                          onChange={(e) =>
                            setAddressNoBuilding2Field(e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </Col>

                  <Col sm={12} md={6}>
                    <Form.Select
                      aria-label="Default select example"
                      className={`block form-control input-relative mb-3 ${
                        addressCityError && "border-danger border-2 border"
                      }`}
                      value={showAddressCity}
                      onChange={(e) => selectCityName(e)}
                    >
                      <option value={-1} className="bg-grey-5">
                        Select City*
                      </option>
                      {addressCityLists?.map((list, index) => (
                        <option key={index} value={index}>
                          {list}
                        </option>
                      ))}
                    </Form.Select>
                    {addressCityError && <p className="errormsg">Required</p>}
                  </Col>

                  <Col sm={12} md={6}>
                    <Form.Select
                      aria-label="Default select example"
                      className={`block form-control input-relative mb-3 ${
                        addressSuburbsError && "border-danger border-2 border"
                      }`}
                      value={showAddressSuburbs}
                      onChange={(e) => selectSuburbsID(e)}
                    >
                      <option value={-1} className="bg-grey-5">
                        Select Area*
                      </option>
                      {addressSuburbsLists?.map((list, index) => (
                        <option key={index} value={index}>
                          {list.AreaName}
                        </option>
                      ))}
                    </Form.Select>
                    {addressSuburbsError && (
                      <p className="errormsg">Required</p>
                    )}
                  </Col>

                  <Col sm={12} md={6}>
                    <FormField
                      value={language ? "Thailand" : "ประเทศไทย"}
                      labelText=""
                      fieldType="text"
                      inputType="Country*"
                    />
                  </Col>
                  <Col sm={12} md={6}>
                    <Form.Select
                      aria-label="Default select example"
                      className={`block form-control input-relative mb-3`}
                      value={addressPostalCodeField}
                      onChange={(e) =>
                        setAddressPostalCodeField(e.target.value)
                      }
                    >
                      <option value={0} className="bg-grey-5">
                        Select Postal Code*
                      </option>
                      {addressPostcodeLists?.map((list, index) => (
                        <option key={index} value={list}>
                          {list}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                </Row>
                {errormsg ? (
                  <p className="errormsg">
                    Please make sure you fill in correct details
                  </p>
                ) : (
                  ""
                )}
                <Row>
                  <Col>
                    <button
                      onClick={submitForm}
                      className="txt-20 link_ btn-card-main  d-table mx-auto wid-25 text-center"
                    >
                      {language ? "Add" : "เพิ่ม"}
                    </button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </form>
        </Modal.Body>
      </Modal>

      {/* change - select adress */}
      <Modal
        show={showOpenAllShipList}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <form
            className="container-lg data_wrap"
            onSubmit={(e) => submitTempShipAddress(e)}
          >
            <Row className="mb-3 text-center">
              <Col>
                <h2 className="mb-4 title-center">Edit Address</h2>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col sm={12} className="px-4 data_wrap">
                <Row className="mb-4">
                  <Col sm={12}>
                    <div className="address_group">
                      <>
                        {showChange === false ? (
                          <>
                            {allShipList !== undefined &&
                              allShipList.map((list, index) => (
                                <div
                                  id={"list_" + index}
                                  className={
                                    tempShipAddressID === list.AddressID
                                      ? "address_list px-2 px-md-3 py-4 border-bottom-1  bg-grey-5"
                                      : "address_list px-2 px-md-3 py-4 border-bottom-1"
                                  }
                                  key={nanoid()}
                                >
                                  <div className=" d-flex align-items-start">
                                    <input
                                      type="radio"
                                      name="list"
                                      className="m-2"
                                      checked={
                                        tempShipAddressID === list.AddressID
                                      }
                                      value={tempShipAddressID}
                                      onChange={() =>
                                        createTempShipAddress(list.AddressID)
                                      }
                                    />

                                    <>
                                      <label
                                        htmlFor="list"
                                        className="px-2 d-inline-block  w-75"
                                      >
                                        {list.AddressName}, {list.AddressPhone}
                                        <br />
                                        {list.AddressLine1},<br />
                                        {list.AddressCity},<br />
                                        {list.AddressSuburbs},<br />
                                        {list.Postcode},{list.Country}
                                      </label>
                                    </>

                                    <div className="edit_wrap p-2 text-center">
                                      {defaultTrue?.AddressID ===
                                        list.AddressID && (
                                        <>
                                          <small className="d-block text-red-1 px-2 border-red mb-2">
                                            {language
                                              ? "Default"
                                              : "ค่าเริ่มต้น"}
                                          </small>
                                        </>
                                      )}

                                      <img
                                        className={`d-table ms-auto img-fluid img-20 link_ edit_tool ${
                                          tempShipAddressID === list.AddressID
                                            ? "d-block"
                                            : "d-none"
                                        }`}
                                        src={
                                          globalIMG +
                                          "/assets/checkout/ico_edit.png"
                                        }
                                        alt="edit"
                                        onClick={() =>
                                          handleShowChange(list.AddressID)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              ))}
                            <div
                              className="d-flex align-items-center link_ w-100 bg-grey-6 p-3 mb-4"
                              onClick={handleShow}
                            >
                              <div className="d-flex align-items-center justify-content-center">
                                <span className="me-2 p-2">
                                  <img
                                    className="d-block img-20"
                                    src={
                                      globalIMG + "/assets/checkout/ico_add.png"
                                    }
                                    alt="ico"
                                  />
                                </span>
                                <span>
                                  {language
                                    ? "Add Address"
                                    : "เพิ่มที่อยู่สำหรับจัดส่งสินค้า"}
                                </span>
                              </div>
                            </div>
                            <Row className="justify-content-center">
                              <Col sm={12}>
                                <button
                                  type="submit"
                                  className="d-table mx-auto txt-20 link_ btn-card-sec text-center text-orange-1"
                                >
                                  {language ? "Confirm" : "ยืนยัน"}
                                </button>
                              </Col>
                            </Row>
                          </>
                        ) : (
                          <>
                            <form>
                              <div className="w-100 d-flex justify-content-end px-3">
                                <div className="w-25 text-end text-decoration-underline text-orange-1 link_">
                                  <div onClick={handleSubmitChange}>
                                    {language ? "Confirm" : "ยืนยัน"}
                                  </div>
                                </div>
                              </div>

                              <div className="address_group">
                                <Row className="mb-3">
                                  <Col sm={12} className="px-4 data_wrap">
                                    <Row>
                                      <Col sm={12} md={6}>
                                        <FormField
                                          value={updateAddressName}
                                          setValue={setUpdateAddressName}
                                          error={errors.updateAddressName}
                                          labelText=""
                                          placeholder={
                                            language
                                              ? "Receipient Name*"
                                              : "ชื่อผู้รับ*"
                                          }
                                          fieldType="text"
                                          inputType="Full Name*"
                                        />
                                      </Col>
                                      <Col sm={12} md={6}>
                                        <input
                                          type="text"
                                          className="form-control mb-2"
                                          placeholder={
                                            language
                                              ? "Receipient Contact*"
                                              : "ติดต่อผู้รับ*"
                                          }
                                          value={updateAddressPhone}
                                          onChange={(e) =>
                                            setUpdateAddressPhone(
                                              e.target.value
                                            )
                                          }
                                        />
                                      </Col>
                                      <Col sm={12}>
                                        <input
                                          type="text"
                                          className="form-control mb-2"
                                          placeholder={
                                            language
                                              ? "Input address in EN*"
                                              : "ใส่ที่อยู่เป็นภาษาไทย*"
                                          }
                                          value={updateAddressLine_1}
                                          onChange={(e) =>
                                            setUpdateAddressLine_1(
                                              e.target.value
                                            )
                                          }
                                        />
                                      </Col>
                                      <Col sm={12}>
                                        <input
                                          type="text"
                                          className="form-control mb-2"
                                          placeholder={
                                            language
                                              ? "Input address in EN -- optional"
                                              : "ใส่ที่อยู่เป็นภาษาไทย"
                                          }
                                          value={updateAddressLine_2}
                                          onChange={(e) =>
                                            setUpdateAddressLine_2(
                                              e.target.value
                                            )
                                          }
                                        />
                                      </Col>
                                      <Col sm={12} md={6}>
                                        <Form.Select
                                          aria-label="Default select example"
                                          className={`block form-control input-relative mb-3 ${
                                            updateAddressCityError &&
                                            "border-danger border-2 border"
                                          }`}
                                          value={updateAddressCityValue}
                                          onChange={(e) =>
                                            selectUpdateCityName(e)
                                          }
                                        >
                                          {updateAddressCityLists?.map(
                                            (list, index) => (
                                              <option key={index} value={index}>
                                                {list}
                                              </option>
                                            )
                                          )}
                                        </Form.Select>
                                        {updateAddressCityError && (
                                          <p className="errormsg">Required</p>
                                        )}
                                      </Col>
                                      <Col sm={12} md={6}>
                                        <Form.Select
                                          aria-label="Default select example"
                                          className={`block form-control input-relative mb-3 ${
                                            updateAddressSuburbsError &&
                                            "border-danger border-2 border"
                                          }`}
                                          value={updateAddressSuburbsValue}
                                          onChange={(e) =>
                                            selectUpdateSuburbsID(e)
                                          }
                                        >
                                          {updateAddressSuburbsLists?.map(
                                            (list, index) => (
                                              <option key={index} value={index}>
                                                {list.AreaName}
                                              </option>
                                            )
                                          )}
                                        </Form.Select>
                                        {updateAddressSuburbsError && (
                                          <p className="errormsg">Required</p>
                                        )}
                                      </Col>

                                      <Col sm={12} md={6}>
                                        <input
                                          type="text"
                                          className="form-control mb-2"
                                          placeholder={
                                            language ? "Country*" : "ประเทศ*"
                                          }
                                          value={updateCountry}
                                          onChange={(e) =>
                                            setUpdateCountry(e.target.value)
                                          }
                                        />
                                      </Col>
                                      <Col sm={12} md={6}>
                                        <Form.Select
                                          aria-label="Default select example"
                                          className={`block form-control input-relative mb-3`}
                                          value={updatePostcode}
                                          onChange={(e) =>
                                            setUpdatePostcode(e.target.value)
                                          }
                                        >
                                          <option
                                            value={0}
                                            className="bg-grey-5"
                                          >
                                            Select Postal Code*
                                          </option>
                                          {updateAddressPostcodeLists?.map(
                                            (list, index) => (
                                              <option key={index} value={list}>
                                                {list}
                                              </option>
                                            )
                                          )}
                                        </Form.Select>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Row>
                                    <Col sm={12}>
                                      <div className="d-flex align-items-center  mb-4">
                                        <input
                                          type="checkbox"
                                          name="set_default_address"
                                          onChange={(e) =>
                                            selectAddress(e.target.checked)
                                          }
                                        />
                                        <label
                                          htmlFor="set_default_address"
                                          className="px-2 cus_checkbox form-check-input"
                                        >
                                          {language
                                            ? "Set as default address"
                                            : "ตั้งเป็นที่อยู่สำหรับเรียกเก็บเงิน"}
                                        </label>
                                      </div>
                                    </Col>
                                  </Row>
                                </Row>
                              </div>
                            </form>
                          </>
                        )}
                      </>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </form>
        </Modal.Body>
      </Modal>

      {/*Modal billing address  */}
      <Modal
        show={showOpenBillingShipList}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <form
            className="container-lg data_wrap"
            onSubmit={(e) => submitTempBillingShipAddress(e)}
          >
            <Row className="mb-3 text-center">
              <Col>
                <h2 className="mb-4 title-center">Select Address</h2>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col sm={12} className="px-4 data_wrap">
                <Row className="mb-4">
                  <Col sm={12}>
                    <div className="address_group">
                      <>
                        <>
                          {allShipList !== undefined &&
                            allShipList.map((list, index) => (
                              <div
                                id={"list_" + index}
                                className={` ${
                                  tempBillingShipAddressID === list.AddressID
                                    ? "address_list px-2 px-md-3 py-4 border-bottom-1  bg-grey-5"
                                    : "address_list px-2 px-md-3 py-4 border-bottom-1"
                                }`}
                                key={nanoid()}
                              >
                                <div className=" d-flex align-items-start">
                                  <input
                                    type="radio"
                                    name="list"
                                    className="m-2"
                                    checked={
                                      tempBillingShipAddressID ===
                                      list.AddressID
                                    }
                                    value={tempBillingShipAddressID}
                                    onChange={() =>
                                      createTempBillingShipAddress(
                                        list.AddressID
                                      )
                                    }
                                  />
                                  <>
                                    <label
                                      htmlFor="list"
                                      className="px-2 d-inline-block  w-75"
                                    >
                                      {list.AddressLine1},<br />
                                      {list.AddressCity},<br />
                                      {list.AddressSuburbs},<br />
                                      {list.Postcode},{list.Country}
                                    </label>
                                  </>
                                </div>
                              </div>
                            ))}
                          <Row className="justify-content-center">
                            <Col sm={12}>
                              <button
                                type="submit"
                                className="d-table mt-4 mx-auto txt-20 link_ btn-card-sec text-center text-orange-1"
                              >
                                {language ? "Confirm" : "ยืนยัน"}
                              </button>
                            </Col>
                          </Row>
                        </>
                      </>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </form>
        </Modal.Body>
      </Modal>
      <Container fluid="xxl" className="mb-5">
        <Row className="data_wrap pt-5 px-2 px-md-3 px-lg-5">
          <Col xs={12}>
            <Link
              to="/"
              className="link_ d-table bg-grey-5 me-0 me-md-3 p-2 mb-4 border-rounded"
            >
              <div className="d-flex align-items-center">
                <span className="me-2 bg-yellow-1 box-40 d-flex align-items-center justify-content-center border-rounded">
                  <HiChevronLeft className="hiCheveronLeft" />
                </span>
                <span className="px-2 txt-bold">
                  {language ? "Continue Shopping" : "เลือกซื้อสินค้าต่อ"}
                </span>
              </div>
            </Link>
          </Col>
          <Col xs={12}>
            <div className="d-flex align-items-center">
              <span>
                <LazyLoad threshold={0}>
                  <img
                    className="d-block img-fluid img-70"
                    src={globalIMG + "/assets/ico_page_basket.png"}
                    alt="basket"
                  />
                </LazyLoad>
              </span>
              <h2 className="px-2 txt-bold txt-super-bold mb-0">
                {language ? "Checkout" : "ชำระเงิน"}
              </h2>
            </div>
          </Col>
        </Row>
      </Container>

      {/* temporatily handleShowSelectedChange*/}
      <Container fluid="xxl" className="mb-5">
        <Row className="px-2 px-md-3 px-lg-5">
          <Col xs={12} md={7}>
            {/* shipping address */}
            <Row className="mb-4">
              <Col xs={12}>
                <h4 className="title-left">
                  {language ? "Shipping Address" : "ที่อยู่สำหรับจัดส่งสินค้า"}
                </h4>
              </Col>
              <Col xs={12}>
                <div className=" shipping_box bg-grey-5 p-3 mb-4">
                  <div className="d-flex align-items-center p-3 w-100">
                    <form
                      className="container-lg"
                      onSubmit={submitChangeHandler}
                    >
                      {allShipList.length !== 0 ? (
                        <>
                          {localShipAddress !== undefined ? (
                            <div>
                              <strong>
                                {localShipAddress?.AddressName}
                                <br /> <br />
                                {localShipAddress?.AddressLine1}
                                <br />
                                {localShipAddress?.AddressCity},
                                {localShipAddress?.AddressSuburbs}, <br />
                                {localShipAddress?.Postcode},
                                {localShipAddress?.Country},
                                <br /> <br />
                                {localShipAddress?.AddressPhone}
                              </strong>
                            </div>
                          ) : (
                            <>
                              {defaultTrue !== undefined ? (
                                <div>
                                  <strong>
                                    {defaultTrue?.AddressName}
                                    <br /> <br />
                                    {defaultTrue?.AddressLine1}
                                    <br />
                                    {defaultTrue?.AddressCity},
                                    {defaultTrue?.AddressSuburbs}, <br />
                                    {defaultTrue?.Postcode},
                                    {defaultTrue?.Country},
                                    <br /> <br />
                                    {defaultTrue?.AddressPhone}
                                  </strong>
                                </div>
                              ) : (
                                <div>
                                  <strong>
                                    {allShipList[0]?.AddressName}
                                    <br /> <br />
                                    {allShipList[0]?.AddressLine1}
                                    <br />
                                    {allShipList[0]?.AddressCity},
                                    {allShipList[0]?.AddressSuburbs}, <br />
                                    {allShipList[0]?.Postcode},
                                    {allShipList[0]?.Country},
                                    <br /> <br />
                                    {allShipList[0]?.AddressPhone}
                                  </strong>
                                </div>
                              )}
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <div
                            className="d-flex align-items-center link_ w-100 justify-content-center"
                            onClick={handleShow}
                          >
                            <div className="d-flex align-items-center justify-content-center">
                              <span className="me-2 p-2">
                                <img
                                  className="d-block img-40"
                                  src={
                                    globalIMG + "/assets/checkout/ico_add.png"
                                  }
                                  alt="ico"
                                />
                              </span>
                              <span>
                                {language
                                  ? "Add shipping address"
                                  : "เพิ่มที่อยู่สำหรับการจัดส่ง"}
                              </span>
                            </div>
                          </div>
                        </>
                      )}
                    </form>
                  </div>
                </div>
                <div className="link_ shipping_box bg-grey-5 p-3 mb-4">
                  <div
                    className="d-flex align-items-center justify-content-center"
                    onClick={() => openAllShipList()}
                  >
                    <span className="me-2 p-2">
                      <img
                        className="d-block img-40"
                        src={globalIMG + "/assets/checkout/ico_change.png"}
                        alt="ico"
                      />
                    </span>
                    <span>{language ? "Change" : "เปลี่ยน"}</span>
                  </div>
                </div>
              </Col>
            </Row>

            {/* billing address */}
            <Row
              className={`mb-4 ${
                allShipList.length !== 0 ? "d-block" : "d-none"
              }`}
            >
              <Col xs={12}>
                <h4 className="title-left">
                  {language ? "Billing Address" : "ที่อยู่สำหรับใบเสร็จรับเงิน"}
                </h4>
              </Col>
              <Col xs={12}>
                <div className="shipping_box bg-grey-5 p-3 mb-4">
                  <div className="d-flex align-items-center p-3 w-100">
                    <form className="container-lg">
                      {allShipList.length !== 0 ? (
                        <>
                          {tempShipAddressID !== undefined ? (
                            <>
                              {tempBillingShipAddressID !== undefined ? (
                                <>
                                  <strong>
                                    {localBillingAddress?.AddressName}
                                    <br /> <br />
                                    {localBillingAddress?.AddressLine1}
                                    <br />
                                    {localBillingAddress?.AddressCity},
                                    {localBillingAddress?.AddressSuburbs},
                                    <br />
                                    {localBillingAddress?.Postcode},
                                    {localBillingAddress?.Country},
                                    <br /> <br />
                                    {localBillingAddress?.AddressPhone}
                                  </strong>
                                </>
                              ) : (
                                <>
                                  {defaultTrue !== undefined ? (
                                    <div>
                                      <strong>
                                        {defaultTrue?.AddressName}
                                        <br /> <br />
                                        {defaultTrue?.AddressLine1}
                                        <br />
                                        {defaultTrue?.AddressCity},
                                        {defaultTrue?.AddressSuburbs}, <br />
                                        {defaultTrue?.Postcode},
                                        {defaultTrue?.Country},
                                        <br /> <br />
                                        {defaultTrue?.AddressPhone}
                                      </strong>
                                    </div>
                                  ) : (
                                    <div>
                                      <strong>
                                        {allShipList[0]?.AddressName}
                                        <br /> <br />
                                        {allShipList[0]?.AddressLine1}
                                        <br />
                                        {allShipList[0]?.AddressLine2},
                                        <br />
                                        {allShipList[0]?.Postcode},
                                        {allShipList[0]?.Country},
                                        <br /> <br />
                                        {allShipList[0]?.AddressPhone}
                                      </strong>
                                    </div>
                                  )}
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              {tempBillingShipAddressID !== undefined ? (
                                <>
                                  <strong>
                                    {localBillingAddress?.AddressName}
                                    <br /> <br />
                                    {localBillingAddress?.AddressLine1}
                                    <br />
                                    {localBillingAddress?.AddressLine2},
                                    <br />
                                    {localBillingAddress?.Postcode},
                                    {localBillingAddress?.Country},
                                    <br /> <br />
                                    {localBillingAddress?.AddressPhone}
                                  </strong>
                                </>
                              ) : (
                                <>
                                  {defaultTrue !== undefined ? (
                                    <div>
                                      <strong>
                                        {defaultTrue?.AddressName}
                                        <br /> <br />
                                        {defaultTrue?.AddressLine1}
                                        <br />
                                        {defaultTrue?.AddressLine2},
                                        <br />
                                        {defaultTrue?.Postcode},
                                        {defaultTrue?.Country},
                                        <br /> <br />
                                        {defaultTrue?.AddressPhone}
                                      </strong>
                                    </div>
                                  ) : (
                                    <div>
                                      <strong>
                                        {allShipList[0]?.AddressName}
                                        <br /> <br />
                                        {allShipList[0]?.AddressLine1}
                                        <br />
                                        {allShipList[0]?.AddressLine2},
                                        <br />
                                        {allShipList[0]?.Postcode},
                                        {allShipList[0]?.Country},
                                        <br /> <br />
                                        {allShipList[0]?.AddressPhone}
                                      </strong>
                                    </div>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </form>
                  </div>
                </div>
                <div
                  className={`link_ shipping_box bg-grey-5 p-3 mb-4 ${
                    resetlocalBillingAddress ? "border-focus" : ""
                  }`}
                >
                  <div
                    className="d-flex align-items-center justify-content-center"
                    onClick={() => openBillingShipList()}
                  >
                    <span className="me-2 p-2">
                      <img
                        className="d-block img-40"
                        src={globalIMG + "/assets/checkout/ico_change.png"}
                        alt="ico"
                      />
                    </span>
                    <span>{language ? "Change" : "เปลี่ยน"}</span>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="mb-4">
              <Col xs={12}>
                <h4 className="title-left">
                  {language ? "Cart Summary" : "สรุปรายการในตะกร้า"}
                </h4>
              </Col>
              {cartList.length !== 0 ? (
                <Col xs={12}>
                  <>
                    <div
                      key={nanoid()}
                      className=" py-2 cart_Row  border-cart-divide"
                      style={{ borderTop: "transparent" }}
                    >
                      {cartList.map((item) => {
                        return (
                          <div key={nanoid()} className="d-flex">
                            <div className="me-3 me-md-4">
                              {item?.image !== undefined && (
                                <>
                                  <img
                                    src={item?.image[0].original}
                                    alt=""
                                    className="img-thumbnail"
                                  />
                                </>
                              )}
                            </div>

                            <div className="w-100">
                              <div className="pt-2 w-100  mb-3">
                                <p className="txt-bold ">
                                  {language ? (
                                    <>
                                      {item?.name?.EN !== undefined && (
                                        <>{item?.name.EN}</>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {item?.name.EN !== undefined && (
                                        <>{item?.name.TH}</>
                                      )}
                                    </>
                                  )}
                                </p>

                                <div className="d-block">
                                  <div className="bg-grey-5 d-table rounded-1 px-2 mb-2">
                                    {language ? (
                                      <> Qty : {item?.quantity}</>
                                    ) : (
                                      <> จำนวน : {item?.quantity}</>
                                    )}
                                  </div>
                                </div>

                                <div className="d-flex text-end w-100 justify-content-end">
                                  <div className="d-block">
                                    {item?.discountRate === "" ||
                                    item?.discountRate === 0 ||
                                    item?.discountRate === null ? (
                                      <h3 className="text-price txt-30 mb-3">
                                        <sub>THB</sub>
                                        {(item?.VolumeDiscountValue > 0 &&
                                        couponValidStatus == 0
                                          ? item?.originalPrice *
                                            (1 -
                                              (item?.VolumeDiscountValue ||
                                                0)) *
                                            item?.quantity
                                          : item?.discountedPrice *
                                            item?.quantity
                                        ).toFixed(2)}
                                      </h3>
                                    ) : (
                                      <>
                                        <div className="d-block">
                                          <h3 className="text-price text-red-1 txt-30 mb-2 text-end">
                                            <sub>THB</sub>
                                            {(item?.VolumeDiscountValue > 0 &&
                                            couponValidStatus == 0
                                              ? item?.originalPrice *
                                                (1 -
                                                  (item?.VolumeDiscountValue ||
                                                    0)) *
                                                item?.quantity
                                              : item?.discountedPrice *
                                                item?.quantity
                                            ).toFixed(2)}
                                          </h3>
                                          <h4 className="text-price text-grey-1 txt-20 mb-2 text-normal">
                                            <sub className="txt-stroke text-normal">
                                              THB
                                            </sub>
                                            <span>
                                              {(
                                                item?.originalPrice *
                                                item?.quantity
                                              ).toFixed(2)}
                                            </span>
                                          </h4>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </>
                </Col>
              ) : (
                <Col xs={12}>
                  <div className="bg-grey-4  p-5 text-center errormsg mb-5">
                    Your shopping cart is empty
                  </div>
                </Col>
              )}
            </Row>
          </Col>

          <Col xs={12} md={5}>
            <div className="payment_box ms-0 ms-md-5">
              {/* summary */}
              <div className="summary_box px-4 py-4 border-grey mb-5">
                <h4 className="mb-4">
                  {language ? "Order Summary" : "สรุปรายการสั่งซื้อ"}
                </h4>
                <div className="w-100 d-flex jsutify-content-around mb-3">
                  <div className="w-50">
                    <p>{language ? "Sub-total" : "ราคารวม"}</p>
                  </div>
                  <div className="w-50 text-end">
                    <h5 className="text-price-h4 ">
                      <sub className="sub-price-h4">THB</sub>
                      {actualFee.toFixed(2)}
                    </h5>
                  </div>
                </div>
                <div className="w-100 d-flex jsutify-content-around mb-3">
                  <div className="w-50">
                    <p>{language ? "VAT (7%)" : "ภาษีมูลค่าเพิ่ม (7%)"}</p>
                  </div>
                  <div className="w-50 text-end">
                    <h5 className="text-price-h4 ">
                      <sub className="sub-price-h4">THB</sub>
                      {cartList.length === 0 ? (
                        "0.00"
                      ) : (
                        <>{discountFee.toFixed(2)}</>
                      )}
                    </h5>
                  </div>
                </div>
                <div className="w-100 d-flex jsutify-content-around mb-3">
                  <div className="w-50">
                    <p>
                      {language ? "Shipping Fees" : "ค่าจัดส่ง"}
                      <a
                        href="https://shoponline.mrdiy.co.th/th/pages/Shipping-Policy"
                        target="_blank"
                        rel="noreferrer"
                        className="ms-2 position-absolute"
                      >
                        <img
                          className="d-block img-fluid img-70"
                          src={globalIMG + "/assets/info_icon.png"}
                          alt="info"
                        />
                      </a>
                    </p>
                  </div>
                  <div className="w-50 text-end">
                    <h5 className="text-price-h4 ">
                      <sub className="sub-price-h4">THB</sub>
                      {cartList.length === 0 ? (
                        "0.00"
                      ) : (
                        <>{parseFloat(shippingFee).toFixed(2)}</>
                      )}
                    </h5>
                  </div>
                </div>
                <div className="w-100 d-flex jsutify-content-around mb-3">
                  <div className="w-25">
                    <p>{language ? "Total" : "ราคารวมทั้งหมด"}</p>
                  </div>
                  <div className="w-75 text-end">
                    <h3 className="text-price-h4 ">
                      <sub className="sub-price-h4">THB</sub>
                      {cartList.length === 0 ? "0.00" : <>{totalAmount}</>}
                    </h3>
                  </div>
                </div>
                {/* {couponValidStatus === 1 && !!couponValue && (
                  <div className="w-100 d-flex jsutify-content-around mb-3">
                    <div className="w-100 text-end">
                      <p className="txt-stroke">
                        <sub className="sub-price-h4">THB</sub>
                        {cartList.length === 0 ? "0.00" : <>{oriTotalAmount}</>}
                      </p>
                    </div>
                  </div>
                )} */}
                <div className="w-100 d-flex jsutify-content-around mb-3">
                  <div className="w-25">
                    <p>Coupon</p>
                  </div>

                  <div className="w-75 text-end">
                    <div className="input-group mb-3">
                      <input
                        value={couponCode}
                        onChange={(e) => setCouponCode(e.target.value)}
                        className="form-control"
                        name="userName"
                        type="text"
                        style={{ height: "44px" }}
                        placeholder=""
                      />
                      <button
                        onClick={handleSubmitCoupon}
                        className="link_ btn-card-sec d-table mx-auto"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>

                <div className="w-100 d-flex jsutify-content-around mb-3">
                  <div className="w-100 text-end">
                    {couponErrorMessage !== "" && (
                      <div className="alert alert-danger d-block p-3 mb-2">
                        <label className="bold float-start">
                          {couponErrorMessage || "Coupon Not Found"}
                        </label>{" "}
                        <span
                          onClick={() => {
                            setCouponErrorMessage("");
                            setCouponValidStatus();
                          }}
                          className="text-red-1 cursor"
                        >
                          Close
                        </span>
                      </div>
                    )}
                    {couponValidStatus === 1 && !!couponValue && (
                      <div className="alert alert-warning d-block p-3 mb-2">
                        <label className="bold float-start">
                          Coupon Applied
                        </label>{" "}
                        <span
                          onClick={() => {
                            window.location.reload();
                          }}
                          className="text-red-1 cursor"
                        >
                          Remove
                        </span>
                      </div>
                    )}
                    {couponValidStatus === 2 && (
                      <div className="alert alert-danger d-block p-3 mb-2">
                        <label className="bold float-start">
                          Invalid Coupon
                        </label>{" "}
                        <span
                          onClick={() => setCouponValidStatus()}
                          className="text-red-1 cursor"
                        >
                          Close
                        </span>
                      </div>
                    )}
                  </div>
                </div>

                <div
                  className="link_ btn-card-sec w-100 text-center mb-4"
                  onClick={() => {
                    submitCheck();
                  }}
                >
                  {language ? "Place Order Now" : "สั่งซื้อตอนนี้"}
                </div>
                <div
                  className={
                    showAddressValidation
                      ? "w-100 d-flex jsutify-content-around mb-3"
                      : "w-100 d-flex jsutify-content-around mb-3 d-none"
                  }
                >
                  <p>{language ? "Addresses are needed." : "ราคารวมทั้งหมด"}</p>
                </div>
              </div>

              {/* shipping info */}
              <div className="text-center d-none">
                <div className="shipping_info_box p-4 d-block">
                  <img
                    className="d-table float-start img-fluid p-1 shipping_info_truck"
                    src={globalIMG + "/assets/ico_delivery.png"}
                    alt="ico"
                  />

                  <h5>
                    {language ? "Shipping Information" : "รายละเอียดการจัดส่ง"}
                  </h5>
                  <small>
                    {language
                      ? "Receive by Friday, August 19, 2022 to 10900, Chatuchak"
                      : "คุณจะได้รับสินค้าภายในวันศุกร์ที่ 19 สิงหาคม 2565 ที่ จตุจักร 10900"}
                  </small>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <form
        name="payFormCcard"
        method="post"
        action="https://ipay.bangkokbank.com/b2c/eng/payment/payForm.jsp"
      >
        <input type="hidden" name="merchantId" value="8012" />
        <input type="hidden" name="amount" value={totalAmount} />
        <input type="hidden" name="orderRef" value={orderID} />
        <input type="hidden" name="currCode" value="764" />
        <input
          type="hidden"
          name="successUrl"
          value={
            process.env.NODE_ENV === "development"
              ? "http://localhost:3000/order-confirmation"
              : "https://shoponline.mrdiy.co.th/th/order-confirmation"
          }
        />
        <input
          type="hidden"
          name="failUrl"
          value={
            process.env.NODE_ENV === "development"
              ? "http://localhost:3000/order-failed"
              : "https://shoponline.mrdiy.co.th/th/order-failed"
          }
        />
        <input
          type="hidden"
          name="cancelUrl"
          value={
            process.env.NODE_ENV === "development"
              ? "http://localhost:3000/order-cancelled"
              : "https://shoponline.mrdiy.co.th/th/order-cancelled"
          }
        />
        <input type="hidden" name="payType" value="N" />
        <input type="hidden" name="payMethod" value={payMethod} />
        <input type="hidden" name="lang" value="E" />
        <input type="hidden" name="remark" value="-" />
      </form>
    </div>
  );
};

export default Checkout;
