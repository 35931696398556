import { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { BsArrowLeftShort } from "react-icons/bs";
import * as yup from "yup";
import { LanguageContext } from "../../contexts/LanguageContext";
import { useValidation } from "../../hooks/validation";
import { Loader, FormField } from "../../components";
import { requestPassword } from "../../api/password";
import { forgotPassword } from "../../api/sms";

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { language } = useContext(LanguageContext);

  //1. Define form schema
  //check if this is international phone
  const emailRegExp = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
  const phoneRegExp = /^[0-9]{10}$/;

  const validationSchema = yup.object().shape({
    username: yup
      .string()
      .required("This field is required")
      .test("usernameValidation", "This field is required", (value, ctx) => {
        // Custom validation logic
        if (!value) return false;

        const isEmail = /^[A-Za-z]/.test(value);
        const isPhone = /^[+0-9]/.test(value);

        if (isEmail && !emailRegExp.test(value)) {
          return ctx.createError({
            message: "Invalid email",
          });
        }
        if (isPhone && !phoneRegExp.test(value)) {
          return ctx.createError({
            message: "Invalid Thai phone number",
          });
        }

        return true;
      }),
  });

  // 2. Initialize the useValidation hook
  const { errors, useField } = useValidation(validationSchema);
  // 3. Define form fields
  const { usernameField, setUsernameField } = useField("username");
  //4. Define custom form submit action
  const method = usernameField.includes("@") ? "email" : "phone";
  const submitForm = async (e) => {
    e.preventDefault();
    if (!usernameField) return;
    setLoading(true);
    try {
      if (method === "email" && emailRegExp.test(usernameField)) {
        const { data } = await requestPassword({ userName: usernameField });

        if (data.Status === "200") {
          navigate("success");
        } else {
          alert("Please try again.");
        }
      }

      if (method === "phone" && phoneRegExp.test(usernameField)) {
        const response = await forgotPassword({ phoneNumber: usernameField });

        if (response.status === 200) {
          navigate("success");
        } else {
          alert(response.message);
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className="account_pg py-5"
      style={{
        backgroundImage: `url(${
          process.env.PUBLIC_URL + "/assets/account_bg.jpg"
        })`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      {loading && <Loader status={loading} />}
      <Container fluid className="my-3 my-md-4">
        <Row>
          <Col>
            <div className="form_login bg-white">
              <form className="container-lg">
                <Row className="mb-3 text-center justify-content-center">
                  <Col sm={12} md={6}>
                    <Link to={`/${language ? "en" : "th"}/user`}>
                      <BsArrowLeftShort className="form_ico_back" />
                    </Link>

                    <h2 className="mb-4">
                      {language
                        ? "Forgot Your Password?"
                        : "ลืมรหัสผ่านหรือไม่?"}
                    </h2>
                    <p className="text-start mb-4 fs-6">
                      {language
                        ? "Please enter your email address or phone number below to receive a password reset link."
                        : "โปรดป้อนที่อยู่อีเมลหรือหมายเลขโทรศัพท์ด้านล่างเพื่อรับลิงก์รีเซ็ตรหัสผ่าน"}
                    </p>

                    <section>
                      <FormField
                        value={usernameField}
                        setValue={setUsernameField}
                        error={errors.username}
                        labelText=""
                        placeholder={
                          language
                            ? "Email or Phone Number"
                            : "อีเมลหรือหมายเลขโทรศัพท์"
                        }
                        fieldType={
                          usernameField.includes("@") ? "email" : "mobile"
                        }
                        inputType="Email or Phone Number"
                      />

                      <button
                        className="btn d-block mb-3 btn-login btn-main w-100"
                        onClick={submitForm}
                      >
                        {language
                          ? "Reset My Password"
                          : "รีเซ็ตรหัสผ่านของฉัน"}
                      </button>
                    </section>
                  </Col>
                </Row>
              </form>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ForgotPassword;
