import axios from "axios";

const cityAreaStateClient = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_ENDPOINT}/cityarea`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export const getCityArea = async () => {
  const { data } = await cityAreaStateClient.get("/");

  return data;
};
