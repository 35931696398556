import { useContext, useState, useEffect } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import "./Footer.css";
import { nanoid } from "nanoid";
import { LanguageContext } from "../../../contexts/LanguageContext";
import { getUsefulLinks } from "../../../api/cms";
import DbdLogo from "../DbdLogo";
import OcpbLogo from "../OcpbLogo";

function Footer() {
  const [point, setPoint] = useState("about");
  //fetch - USEFUL LINK LOOP
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const { language } = useContext(LanguageContext);
  const location = useLocation();
  const pageLocated = location.pathname;
  let globalIMG = process.env.PUBLIC_URL;

  const year = new Date().getFullYear();

  const onTabChange = (e) => {
    if (e === "about") {
      setPoint("about");
    } else if (e === "usefullinks") {
      setPoint("usefullinks");
    } else if (e === "contact") {
      setPoint("contact");
    } else {
      setPoint("");
    }
  };

  useEffect(() => {
    const getCMSDocument = async () => {
      try {
        setLoading(true);
        const { documents } = await getUsefulLinks({ language });
        setData(documents);
      } catch (ex) {
        setError(ex);
      } finally {
        setLoading(false);
      }
    };

    getCMSDocument();
  }, [language]);

  {
    loading && <h1>...loading</h1>;
  }

  return (
    <div
      className={`App-footer ${
        pageLocated === "/admin" || pageLocated === "/admin/dashboard"
          ? "d-none"
          : "d-block"
      }`}
    >
      <>
        <Tabs
          defaultActiveKey="about"
          onSelect={(e) => onTabChange(e)}
          id="footer_tab"
          variant="tabs"
          className="footer_tabs justify-content-center justify-content-sm-start"
          justify
        >
          <Tab eventKey="about" title={language ? "About" : "เกี่ยวกับ"}>
            {point === "about" && (
              <div className="d-flex align-items-center px-4 py-5 justify-content-between">
                <div className="d-flex flex-column flex-lg-row">
                  <a
                    href="https://shoponline.mrdiy.com/th/page/237/%E0%B9%80%E0%B8%81%E0%B8%B5%E0%B9%88%E0%B8%A2%E0%B8%A7%E0%B8%81%E0%B8%B1%E0%B8%9A%E0%B9%80%E0%B8%A3%E0%B8%B2"
                    target="_blank"
                    rel="noreferrer"
                    className="text-start mb-2 mx-lg-4"
                  >
                    {language ? "About Us" : "เกี่ยวกับเรา"}
                  </a>
                  <a
                    href="https://shoponline.mrdiy.com.my/default/corporate"
                    target="_blank"
                    rel="noreferrer"
                    className="text-start mb-2 mx-lg-4"
                  >
                    {language ? "Corporate  " : "องค์กร"}
                  </a>
                  <a
                    href="https://shoponline.mrdiy.com/th/page/116/Contact-Us/"
                    target="_blank"
                    rel="noreferrer"
                    className="text-start mb-2 mx-lg-4"
                  >
                    {language ? "Contact Us" : "ติดต่อเรา"}
                  </a>
                  <a
                    href="https://shoponline.mrdiy.com/th/storelocator/"
                    target="_blank"
                    rel="noreferrer"
                    className="text-start mb-2 mx-lg-4"
                  >
                    {language ? " Store Location" : "สถานที่ร้านค้า"}
                  </a>
                </div>

                <div className="d-flex flex-column align-items-end">
                  <p className="small fw-bold">
                    {language ? "Verified by" : "รับรองความปลอดภัยโดย"}
                  </p>

                  <div className="verified-logos d-flex align-items-center">
                    <OcpbLogo />
                    <DbdLogo />
                  </div>
                </div>
              </div>
            )}
          </Tab>
          <Tab
            eventKey="usefullinks"
            title={language ? "Useful Links" : "เงื่อนไขและนโยบายของเรา"}
          >
            {point === "usefullinks" && (
              <div>
                <div
                  className="d-flex flex-column flex-lg-row px-4 py-5 w-85"
                  id="tab_useful"
                >
                  {data && data.length > 0 &&
                    data.map((item) => {
                      return (
                        <Link
                          to={`/${language ? "en" : "th"}/pages/${item.NodeAlias}`}
                          className="text-start mb-2 mx-lg-4"
                          key={nanoid()}
                        >
                          {item.DocumentName}
                        </Link>
                      );
                    })}
                </div>
              </div>
            )}
          </Tab>
          <Tab eventKey="contact" title={language ? "Contact Us" : "ติดต่อเรา"}>
            {point === "contact" && (
              <div>
                <div className="d-flex flex-column  px-4 py-5 w-85">
                  <div className="d-flex align-items-start mb-2">
                    <span className="me-1 p-1">
                      <img
                        className="d-block  img-20"
                        src={globalIMG + "/assets/ico_address.png"}
                        alt="ico"
                      />
                    </span>
                    <span>
                      {language
                        ? "889, 889/1 Moo 3, Phraek Sa Mai, Mueang Samut Prakan 10280, Thailand."
                        : "889, 889/1 หมู่ที่ 3 ตำบลแพรกษาใหม่ อำเภอเมืองสมุทรปราการ จังหวัดสมุทรปราการ รหัสไปรษณีย์ 10280 ประเทศไทย"}
                    </span>
                  </div>
                  <div className="d-flex align-items-start mb-2">
                    <span className="me-1 p-1">
                      <img
                        className="d-block  img-20"
                        src={globalIMG + "/assets/ico_contact.png"}
                        alt="ico"
                      />
                    </span>
                    <span>02-136-7401</span>
                  </div>
                  <div className="d-flex align-items-start mb-2">
                    <span className="me-1 p-1">
                      <img
                        className="d-block  img-20"
                        src={globalIMG + "/assets/ico_fax.png"}
                        alt="ico"
                      />
                    </span>
                    <span>02-136-7404</span>
                  </div>
                  <div className="d-flex align-items-start mb-2">
                    <span className="me-1 p-1">
                      <img
                        className="d-block  img-20"
                        src={globalIMG + "/assets/ico_mail.png"}
                        alt="ico"
                      />
                    </span>
                    <span>customercare.th@mrdiy.com</span>
                  </div>
                </div>
              </div>
            )}
          </Tab>
        </Tabs>
        {/* footer bot  */}
        <div className="footer_bot bg-grey-1 px-3 px-md-5 py-2">
          <p className="text-grey-footer txt-15 text-center text-lg-start mb-2 mb-lg-1">
            {language ? (
              <>
                Copyright © {year} MR.D.I.Y. (Bangkok) Co.,Ltd. | MR.DIY (Co.No.
                : 0105558162529) All rights reserved.
              </>
            ) : (
              <>
                ลิขสิทธิ์ © {year} MR.D.I.Y. (Bangkok) Co.,Ltd. | MR.DIY (Co.No.
                : 0105558162529) สงวนลิขสิทธิ์.
              </>
            )}
          </p>
        </div>
      </>
    </div>
  );
}

export default Footer;
