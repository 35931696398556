import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { nanoid } from "nanoid";
import { VscClose } from "react-icons/vsc";
import Accordion from "react-bootstrap/Accordion";
import "./CategoryPanel.css";
import { LanguageContext } from "../../../contexts/LanguageContext";
import { getCategories } from "../../../api/category";
import clsx from "clsx";
import { convertToURLParams } from "../../../utils/string";

function CategoryPanel({ open, setOpen }) {
  const { language } = useContext(LanguageContext);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      const { status, data } = await getCategories(language);

      if (status !== 200) return;
      setCategories(data);
    };

    fetchCategories();
  }, [language]);

  return (
    <>
      <div
        className={clsx(
          "data_wrap categories_panel bg-grey-4_",
          open ? "d-block expanded" : "d-none_"
        )}
      >
        <div className="Cartlist_Panel_Box" onClick={() => setOpen(false)}>
          <VscClose className="vs_close" />
        </div>

        <img
          src="/logo_diy.png"
          alt="Mr DIY"
          className="mx-auto d-block mb-4"
        />

        <div className="d-block mb-3">
          <Accordion className="main_accordion">
            {categories?.map((item, index) => (
              <Accordion.Item
                className="main_accordionitem"
                eventKey={index}
                key={nanoid()}
              >
                <div className="menu-container">
                  <div className="custom_accordion_body link_ py-4_">
                    <Link
                      to={`/${language ? "en" : "th"}/${convertToURLParams(language ? item?.name : item?.thaiName)}-cat.${btoa(item?.id)}`}
                      onClick={() => setOpen(false)}
                    >
                      {language ? item.name : item.thaiName}
                    </Link>
                  </div>
                </div>
              </Accordion.Item>
            ))}
          </Accordion>
        </div>
        <div className="social-icons-box">
          <label className="bold d-block mb-2 text-center">
            Connect With Us
          </label>
          <ul>
            <li>
              <a href="https://www.facebook.com/mrdiy2u" target="_blank">
                <img src="/assets/social-ico-fb.png" />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/mrdiy.thailand"
                target="_blank"
              >
                <img src="/assets/social-ico-ig.png" />
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/channel/UCyBZ5RdutSVWlUEWk2s6f9A"
                target="_blank"
              >
                <img src="/assets/social-ico-youtube.png" />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="menu-backdrop" onClick={() => setOpen(false)}></div>
    </>
  );
}

export default CategoryPanel;
