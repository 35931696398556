import { createContext, useState } from "react";

export const OrderStatusContext = createContext(""); //context OrderStatus

export const OrderStatusProvider = ({ children }) => {
  const [orderDatas, setOrderDatas] = useState([]);

  return (
    <OrderStatusContext.Provider
      value={{
        orderDatas,
        setOrderDatas,
      }}
    >
      {children}
    </OrderStatusContext.Provider>
  );
};
