import { useContext } from "react";
import LogoutDropdown from "./LogoutDropdown";
import { AuthContext } from "../../contexts/AuthContext";

function Logout() {
  const { handlelogout } = useContext(AuthContext);

  return <LogoutDropdown logout={handlelogout} />;
}

export default Logout;
