import axios from "axios";

const emailClient = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_ENDPOINT}/email`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

const requestCode = async (params) => {
  const { data } = await emailClient.post("/verification-code", params);
  return data;
};

const verifyEmail = async ({ email, verificationCode }) => {
  const { data } = await emailClient.post("/verify", {
    email,
    verificationCode,
  });

  return data;
};

export default {
  requestCode,
  verifyEmail,
};
