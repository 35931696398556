import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { LanguageContext } from "../../contexts/LanguageContext";
import { Loader } from "../";
import { AuthContext } from "../../contexts/AuthContext";
import "./LoginForm.css";

function ThankYouRedirect() {
  const [loading, setLoading] = useState(false);

  const { language } = useContext(LanguageContext);
  const { emailOrPhone } = useContext(AuthContext);

  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    setTimeout(() => navigate((language ? "/en" : "/th") + "/user"), 3000);
  }, []);

  return (
    <div
      className="account_pg py-5"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL + "/assets/account_bg.jpg"})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      {loading && <Loader status={loading} />}
      <Container fluid className="my-3 my-md-4">
        <Row>
          <Col>
            <div className="form_login bg-white">
              <form className="container-lg">
                <Row className="mb-3 text-center">
                  <Col>
                    <h2 className="mb-4">
                      {language ? "Thank You" : "ขอบคุณครับ"}
                    </h2>
                    <p>
                      {language ? "Account registration for " : "การลงทะเบียนเพื่อสมัครสมาชิกสำหรับอีเมล "}
                      <span className="txt-bold">{emailOrPhone}</span> is
                      {language ? "successful." : "สำเร็จแล้ว เรากำลังพาคุณกลับไปยังหน้าก่อนหน้านี้"}
                    </p>
                  </Col>
                </Row>
              </form>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ThankYouRedirect;
