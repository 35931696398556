import { ExclamationCircleIcon } from "@heroicons/react/20/solid";

function FormField({
  labelText,
  fieldType,
  inputType,
  placeholder,
  error,
  value,
  setValue,
  loginType,
  ifDisable,
  setError,
}) {
  return (
    <div id={fieldType}>
      <label
        className={`mb-1 ${labelText !== "" ? "d-block" : "d-none"}`}
        htmlFor={fieldType}
      >
        {labelText}
      </label>
      <div className="input-relative mb-3">
        <input
          id={inputType.trim().replace(/\s/g, "")}
          className={`block form-control ${error ? "border-focus" : ""}`}
          name={labelText}
          type={fieldType}
          placeholder={placeholder}
          value={value}
          onChange={(e) => {
            setValue(e.target.value.trim());
            !!setError && setError(false);
          }}
          disabled={ifDisable == "true" ? "disabled" : ""}
        />
        {error && (
          <div className="">
            <ExclamationCircleIcon
              className="img-20 text-red-1 input-absoulte"
              aria-hidden="true"
            />
          </div>
        )}
      </div>
      {error && <p className="mt--2 errormsg">{error}</p>}
    </div>
  );
}

export default FormField;
